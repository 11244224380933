import { Cascader, Col, Input, Row, Table, Tag, message, Button, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDebounce } from "../../../common/debounce";

const EndpointDetailsGroup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  const isInstanceId = queryParams.get("instanceId");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [endpoint, setEndpoint] = useState([]);
  const [endpointGroup, setEndpointGroup] = useState([]);

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {}, [paginationParams, search, filterBy, filteredValue]);

  useEffect(() => {
    fetchGroups();
  }, [debouncedValue, currentPage, paginationParams]);

  const fetchGroups = () => {
    let pageDetails = {
      domainId: 1,
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      instanceId: Number(isInstanceId),
      Filter: {
        filterBy: search ? "groupName" : "localGroups",
        value: search,
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            text: grp.groupName,
            people: grp.users.length,
            application: grp.applications.length,
            selectedApps: grp.applications ? grp.applications.map((app) => app.id) : [],
            selectedUsers: grp.users ? grp.users.map((user) => user.id) : [],
            ...grp,
          }));
        }
        setEndpointGroups(Data);
        setTotalCount(res?.data?.totalGroups);
        setLoadingData(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const tab2columns = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
    },
    {
      title: "Users",
      dataIndex: "people",
      key: "people",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);
    console.log(isTabChange);
    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpointGroup([]);
    }
    fetchGroups();
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={tab2columns}
                dataSource={endpointGroups}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                }}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointDetailsGroup;
