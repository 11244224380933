//DIRECTORY AND PRIVELAGED ACCESS MANAGEMENT
export const REACT_APP_PAM_API = process.env.REACT_APP_API_PAM_URL;

export const REACT_APP_API_PAM_URL = process.env.REACT_APP_API_PAM_URL;

//DIDS
export const REACT_APP_API_DID_URL = process.env.REACT_APP_API_DID_URL;

//TRASACTIONS LOGS
export const REACT_APP_API_TRANS_URL = process.env.REACT_APP_API_TRANS_URL;

//OKTA
export const REACT_APP_API_OKTA = process.env.REACT_APP_API_OKTA;
export const REACT_APP_API = process.env.REACT_APP_API;

//Domain
export const REACT_APP_URL = process.env.REACT_APP_URL;

//OKTA TOKEN
export const AUTH_TOKEN = () => {
  let token = localStorage.getItem("token");
  return `${token}&DOMAIN&${REACT_APP_URL}`;
};
