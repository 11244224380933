import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  Input,
  Button,
  Select,
  Row,
  Col,
  Typography,
  Dropdown,
  Menu,
} from "antd";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams, useLocation } from "react-router-dom";
import "../../styles/ServiceAccount.scss"

const { Title, Text } = Typography;
const { Option } = Select;

const ServiceAssignPermissions = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
 

  return (
    <div className="rule-container">
      <Row style={{paddingRight:"3rem"}}>
        <Col span={12}>
            <h3>Let Set this Up</h3>
          <Row>
            <Col span={24}>
            <p >Assign Permissions for</p>
                <Select
                      defaultValue={"Endpoint Groups"}
                      placeholder="Select Identity Group"
                      options={[
                        { label: "Identities (AD security Groups)", value: "identities" },
                        { label: "User Groups (Authnull Managed)", value: "User groups" },
                        { label: "Endpoint Groups", value: "endpoints groups" },
                        { label: "Endpoints", value: "endpoints" },
                        { label: "Apps", value: "apps" },
                      ]}
                      allowClear
                    />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
            <p >Endpoint Groups</p>
                <Select
                      defaultValue={"Endpoint Groups #1"}
                      options={[
                        { label: "Endpoint Groups #2", value: "groups #2" },
                        { label: "Endpoint Groups #3", value: "groups #3" },
                        { label: "Endpoint Groups #4", value: "groups #4" }, 
                      ]}
                      mode="multiple"
                      allowClear
                    />
            </Col>
          </Row>
          <Row></Row>
          <Row
          >
              <Col span={24}>
              <p >Service Account</p>
                <Select
                      defaultValue={"mysql-admin"}
                      options={[
                        { label: "mysql-admin", value: "admin" },
                        { label: "mysql-authnull", value: "authnull" },
                        { label: "mysql-kloudone", value: "kloudone" }, 
                      ]}
                      allowClear
                    />
              </Col> 
          </Row>
          <Row  
          >
              <Col span={24}>
                <Row gutter={12}>
                <Col span={24}>
                <p >Can Access</p>  
                    <Select
                    defaultValue={['Endpoint Groups #2' , 'Endpoint Groups #3']}
                     options={[
                      { label: "Endpoint Groups #1", value: "groups #1" },
                      { label: "Endpoint Groups #3", value: "groups #3" },
                    ]}
                    mode="multiple"
                    allowClear
                    >
                    </Select>
                  </Col>
                </Row>
              </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              <Button
                type="primary"
                className="rules-dropdown"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col
          span={12}
          style={{ paddingLeft: "2rem" }}
          className=" add-servicerule-version"
          
        >
          <Row className="commit-container">
            <Col className="version-history" span={24}>
              {/* Replace this part with your dynamic version history data */}
                <>
                  <Row>
                    <Col>
                      <h2 className="version-history-title">Assignment History</h2>
                    </Col>
                  </Row>
                  <Col span={24}>
                        <div className="github-commit-card">
                          <div className="commit-header">
                            <p className="commit-info">
                              Last updated by Asif Ali @ 12-12-2023
                            </p>
                          </div>
                          <div className="commit-details">
                            {/* <p className="commit-message">abcc</p> */}
                            <div className="commit-code">
                              <pre className="code-snippet">
                                        <div className="code-line"
                                        >
                                          Endpoint Group: {"group1#k1-service-account"}
                                        </div>
                                <div
                                >
                                  <h5
                                    style={{ marginTop: "0.5rem" }}
                                    className="code-line"
                                  >
                                   was assigned access to: {"Endpoint group #2 Endpoint Groups #3"}
                                  </h5>
                                </div>
                              </pre>
                            </div>
                          </div>
                        </div>
                  </Col>
                  <Col span={24}>
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 12-12-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       <div className="commit-code">
                         <pre className="code-snippet">
                                   <div className="code-line"
                                   >
                                     Endpoint Group: {"group1#k1-service-account"}
                                   </div>
                           <div
                           >
                             <h5
                               style={{ marginTop: "0.5rem" }}
                               className="code-line"
                             >
                               was assigned access to: {"Endpoint group: Group #2"}
                             </h5>
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
             </Col>
             <Col span={24}>
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 12-12-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       {/* <p className="commit-message">abcc</p> */}
                       <div className="commit-code">
                         <pre className="code-snippet">
                                   <div className="code-line"
                                   >
                                     Endpoint Group: {"group1#k1-service-account"}
                                   </div>
                           <div 
                           >
                             <h5
                               style={{ marginTop: "0.5rem" }}
                               className="code-line"
                             >
                               was assigned access to: {"Endpoint group: Group #2"}
                             </h5>
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
             </Col>
                </>
            </Col>
          </Row>
        </Col>
    </div>
  );
};

export default ServiceAssignPermissions;