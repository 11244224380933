import { Cascader, Col, Input, Row, Table, Tag, message, Button, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { MoreOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { useDebounce } from "../../../common/debounce";
import AssignEndpointUser from "../endpointsUsers/AssignEndpointUser";

const EndpointDetailsUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [epmUser, setEpmUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  const isInstanceId = queryParams.get("instanceId");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [endpoint, setEndpoint] = useState([]);
  const [Isassignwallet, setIsassignwallet] = useState(false);

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchEPMUsers();
  }, [debouncedValue, currentPage, paginationParams]);

  const fetchEPMUsers = (id) => {
    let pageDetails = {
      domainId: 1,
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: [Number(isInstanceId)],
      filter: {
        filterBy: "",
        value: "",
      },
      search: search,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res, totalCount) => {
        setIsLoading(false);
        setEpmUser(res?.data?.epmUsers);
        console.log(res);
        setTotalCount(res.data.totalCount);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Credentials are invalid") {
          logOutUser();
        } else {
        }
      });
  };

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return true;
    } else if (diff > 10) {
      return false;
    }
    return false;
  };

  const tab1columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      dataIndex: "instances",
      title: "Endpoints",
      key: "instances",
      render: (tags, record) => (
        <span>
          {tags.map((tag) => {
            const lastActive = moment
              .unix(tag.lastActive)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD HH:mm:ss");
            const diff = moment().diff(lastActive, "minutes");
            return (
              <Tag color={diff >= 20 ? "red" : diff >= 10 ? "orange" : "green"} key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Credentials",
      dataIndex: "countOfCredentials",
      key: "countOfCredentials",
    },
    {
      title: "Action",
      key: "action",
      render: (_value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedRecords(record);
                      setIsassignwallet(true);
                    }}
                  >
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/sessionRecordings?epmUser=${record.userName}`}>
                    View Session Recordings
                  </Link>
                ),
              },
              {
                key: "checkout-user",
                label: (
                  <Link
                    to={`/checkoutUser/${record.userId}`}
                    state={{
                      instanceName: record.instances[0].hostname,
                      instanceId: JSON.stringify(record.instances[0].instanceId),
                      epmUsername: record.userName,
                      os: record.instances[0].os,
                    }}
                  >
                    Checkout User
                  </Link>
                ),
                disabled: EndpointStatus(record.instances.map((ins) => ins.lastActive)),
              },

              {
                key: "reset-credential",
                label: (
                  <Link
                    to={`/reset-credentials?epmuser=${record.userName}&epmuserId=${record.userId}`}
                  >
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);
    console.log(isTabChange);
    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpoint([]);
    }
    fetchEPMUsers();
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={tab1columns}
                dataSource={epmUser}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                }}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {Isassignwallet && (
        <AssignEndpointUser
          Isassignwallet={Isassignwallet}
          setIsassignwallet={setIsassignwallet}
          selectedRecords={selectedRecords}
        />
      )}
    </>
  );
};

export default EndpointDetailsUser;
