import { CopyTwoTone, InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Tabs, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL } from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";

export const AddEndpoint = () => {
  const [notification, setNotification] = message.useMessage();

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Add Agent</h2>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <p>
                Agents register from the command line. This agent key will allow you to communicate
                to this control plane to complete agent registration.
              </p>
            </Col>
          </Row>
        </Col>
        <Row className="tab-container">
          <Col span={24}>
            <Tabs
              items={[
                {
                  key: 1,
                  label: "Linux",
                  children: <AddAgent notification={notification} />,
                },
                {
                  key: 2,
                  label: "Windows",
                  children: <AddAgent notification={notification} />,
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export const AddAgent = ({ notification }) => {
  const [code, setCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchAgentKey();
  }, []);

  const fetchAgentKey = () => {
    let keyData = {
      domainId: 1,
      expired: false,
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/generateAgentkey`, keyData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        if (res.data) {
          setCode(res.data.agentKey);
        }
      })
      .catch((err) => {
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch agent key",
          });
        }
      });
  };
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row>
                {/* <Col span={6}>
                  {" "}
                  <h4>Step 1: Download the agent</h4>
                  <a
                    onClick={() =>
                      window.open(
                        "https://drive.google.com/file/d/19JaMUpgk0TRKFOc8ZrDIs2f4BGSgRfrL/view",
                        "_blank",
                        "status=no,resizable=no"
                      )
                    }
                  >
                    Google drive
                  </a>
                </Col> */}
                {/* <Col span={2}>
                  {" "}
                  <p>or</p>
                </Col> */}
                <Col span={16}>
                  {" "}
                  <h4>Step 1: Download the agent by cloning the REPO</h4>
                  <a href="https://github.com/authnull0/agent-binaries" target="_blank">
                    Github repo link
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col span={24}>
              <h4>
                {" "}
                Step 2: Configure the APP.ENV in the home directory of the agent (OR SRC folder of
                the REPO)
              </h4>
            </Col>
            <Col span={16}>
              <span>
                <a
                  href="https://docs.google.com/document/d/1TYjDscMghXPcB7iXqq_AIFuRF1nhQywG_ZnUzqdSPJo/edit"
                  target="_blank"
                >
                  Sample App.ENV Link
                </a>{" "}
                |{" "}
                <a
                  href="https://help.authnull.com/docs/how-tos/howtoonboardendpoint/"
                  target="_blank"
                >
                  {`  `} What do these fields mean?
                </a>
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col span={24}>
              <h4>
                Step 3: Copy the following agent token (AGENT_TOKEN field in the app.env config
                file)
              </h4>
            </Col>
            <Col span={16}>
              <Input
                disabled
                value={code}
                placeholder="Enter your username"
                suffix={
                  <Tooltip title="Copy token">
                    <CopyTwoTone onClick={() => handleCopy(code)} />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col span={24}>
              <h4>Step 4: Run the agent using any one of the following steps</h4>
            </Col>
            <Col span={24}>
              a) Using the given binary ($./endpoint-agent)
              <br />
              <br />
              b) By running go run src/main.go (ensure app.env is in the home folder)
              <br />
              <br />
              c) By building the agent (go build) and then running using the binary
              ($./endpoint-agent)
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: "1rem" }}>
        <Col span={8}>
          <Space>
            <Button onClick={() => navigate(-1)}>Cancle</Button>
            <Button type="primary" htmlType="submit" onClick={() => navigate(-1)}>
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
