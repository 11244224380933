import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Tag,
  Button,
  Input,
  message,
  Cascader,
  notification,
  Popconfirm,
} from "antd";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { useLocation, Link, useParams } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API } from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";
import { useDebounce } from "../../../common/debounce";

const Context = React.createContext({
  name: "Default",
});

const Connection = () => {
  const [connectionData, setConnectionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [endpoints, setEndpoints] = useState([]);
  const [epmUser, setEpmUser] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const PAGESIZE = 10;
  let userEmail = localStorage.getItem("UserName");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  // const [notify, contextHolder] = notification.useNotification();
  const [isNotification, setNotification] = message.useMessage();
  const [endpointFilter, setEndpointFilter] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const { publicIpAddress } = useParams();
  const debouncedValue = useDebounce(search, 500);

  let endpointId = [];
  // let found;

  useEffect(() => {
    fetchConnection();
    return () => {
      if (hostAddress) {
        connectionData.some((item) =>
          item.endpoints.some((endpoint) => {
            if (endpoint.publicIpAddress === hostAddress) {
              endpointId = item.endpoints.map((end) => end.instanceId);
              console.log(endpointId);
            }
          })
        );

        console.log(endpointId);
      }
    };
  }, [filterBy, filteredValue, currentPage, debouncedValue, endpointFilter]);
  useEffect(() => {
    fetchInstance();
  }, []);
  const handleDelete = (record) => {
    let connectId = [];
    connectId.push(record.jumpServerConnectionId);
    let payload = {
      jumpSeverConnectionId: connectId,
      token: AUTH_TOKEN(),
    };
    axios
      .delete(`${REACT_APP_PAM_API}/connections/deleteConnection`, {
        data: payload,
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        isNotification.open({
          type: "success",
          content: "Connection deleted successful",
        });
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "success",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to delete connection",
          });
        }
      });
  };

  const columns = [
    { dataIndex: "publicIpAddress", title: "Jump Server Url", key: "publicIpAddress" },
    { dataIndex: "user", title: "User", key: "user" },
    {
      dataIndex: "walletuser",
      title: "Wallet User",
      key: "walletuser",
      render: (tags) => (
        <span>
          <Tag color="blue" key={tags}>
            {tags}
          </Tag>
        </span>
      ),
    },
    { dataIndex: "protocol", title: "Protocol", key: "protocol" },
    {
      dataIndex: "endpoints",
      title: "Endpoints",
      key: "endpoints",
      render: (tags) => (
        <span>
          {tags.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    { dataIndex: "status", title: "Status", key: "status" },
    {
      dataIndex: "connect",
      title: "Connect",
      key: "connect",
      render: (text, record) => (
        <span>
          <Link
            disabled={record?.status === "false" ? true : false}
            onClick={() => {
              api["info"]({
                message: "Entering Servlet:",
                description: "Please approve credentials to proceed and Session will be recorded.",
                placement: "topLeft",
              });
              return window.open(
                `https://api.authnull.authnull.com/guacamole-servlet/?hostName=${
                  record.endpoints[0].publicIpAddress
                }&userName=${record.user}&ipAddress=${
                  record.publicIpAddress
                }&bearerToken=${AUTH_TOKEN()}&loggedInUser=${userEmail}&protocol=${
                  record.protocol
                }&hostlabel=${record.endpoints[0].hostname}`,
                `width=1024`,
                `height=768`
              );
            }}
          >
            Connect Now
          </Link>
        </span>
      ),
    },
    {
      dataIndex: "delete",
      title: "Action",
      key: "delete",
      render: (_, record) => (
        <Popconfirm title="Sure to Delete Connection?" onConfirm={() => handleDelete(record)}>
          <DeleteOutlined style={{ color: "red" }} />
        </Popconfirm>
      ),
    },
  ];
  const fetchConnection = () => {
    let pageDetails = {
      pageId: currentPage,
      pageSize: PAGESIZE,
      search,
      filter: {
        filterBy: publicIpAddress ? "publicipaddress" : filterBy,
        value: publicIpAddress ?? filteredValue,
      },
      endpoints: filterBy === "endpoint" ? endpointFilter : [],
      token: AUTH_TOKEN(),
      domainId: 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/connections/listConnections`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.total);
        const data = res?.data?.jumpServerConnections.map((data) => {
          return {
            ...data,
            status: data?.endpoints?.map((i) => (i.status === false ? "Inactive" : "Active")),
          };
        });
        setConnectionData(data);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "success",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch connections",
          });
        }
      });
  };
  const fetchInstance = () => {
    let payload = {
      domainId: 1,
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoints(
          res?.data?.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIpAddress,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };
  const option = [
    {
      value: "endpoint",
      label: "Endpoints",
      children: endpoints,
    },
    {
      value: "protocol",
      label: "Protocol",
      children: [
        {
          value: 1,
          label: "SSH",
        },
        {
          value: 2,
          label: "RDP",
        },
        {
          value: 3,
          label: "VNC",
        },
        {
          value: 4,
          label: "Telnet",
        },
      ],
    },
  ];
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
    setEndpointFilter(data ? [data[1]?.value] : []);
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {contextHolder}

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Connections on Jump Server</h2>
            </Col>
            <Col>
              <Link to={"/connections/createConnection"}>
                <Button type="primary">Create Connection</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      {/* <div>
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Endpoint"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={console.log("c")}
                          onSearch={console.log("a")}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "endpoint",
                              label: "Endpoints",
                            },
                            {
                              value: "endpointUser",
                              label: "Endpoints User",
                            },
                          ]}
                        />
                      </div> */}

                      <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={connectionData.filter(
                  (connection) => !search || (search && connection.user.includes(search))
                )}
                pagination={{
                  pageSize: PAGESIZE,
                  total: totalCount,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Connection;
