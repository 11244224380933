import { Col, Input, Row, Table, Tag, message, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL } from "../../constants";
import { logOutUser } from "../../common";
import { Link } from "react-router-dom";
import { useDebounce } from "../../common/debounce";

const MyConnections = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [isNotification, setIsNotification] = message.useMessage();
  const [connectionData, setConnectionData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  let userEmail = localStorage.getItem("UserName");
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);
  useEffect(() => {
    fetchMyConnection();
  }, [debouncedValue, paginationParams]);

  const columns = [
    {
      title: "Endpoint Username",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Jump Server Url",
      dataIndex: "publicIpAddress",
      key: "publicIpAddress",
    },
    {
      dataIndex: "endpoints",
      title: "Endpoints",
      key: "endpoints",
      render: (tags) => (
        <span>
          {tags.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "Protocol",
      dataIndex: "protocol",
      key: "protocol",
    },
    {
      dataIndex: "connect",
      title: "Connect",
      key: "connect",
      render: (text, record) => (
        <span>
          <Link
            disabled={record?.status === "false" ? true : false}
            onClick={() => {
              api["info"]({
                message: "Entering Servlet:",
                description: "Please approve credentials to proceed and Session will be recorded.",
                placement: "topLeft",
              });
              return window.open(
                `https://api.authnull.authnull.com/guacamole-servlet/?hostName=${
                  record.endpoints[0].publicIpAddress
                }&userName=${record.user}&ipAddress=${
                  record.publicIpAddress
                }&bearerToken=${AUTH_TOKEN()}&loggedInUser=${userEmail}&protocol=${
                  record.protocol
                }&hostlabel=${record.endpoints[0].hostname}`,
                `width=1024`,
                `height=768`
              );
            }}
          >
            Connect Now
          </Link>
        </span>
      ),
    },
  ];

  const fetchMyConnection = () => {
    const userId = localStorage.getItem("userId");

    let data = {
      pageId: paginationParams.currentPage,
      pageSize: paginationParams.pageSize,
      filter: {
        filterBy: "",

        value: "",
      },
      search,
      userId: parseInt(userId),
      endpoints: [],
      token: AUTH_TOKEN(),
      domainId: 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/connections/listAssignedConnections`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setConnectionData(res?.data?.jumpServerConnections);
        setTotalCount(res?.data?.total);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch connections",
          });
        }
      });
  };

  return (
    <>
      {setIsNotification}
      {contextHolder}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">My Connections</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={connectionData}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MyConnections;
