export const userRoles = {
  ADMIN: {
    canAccess: [
      "/",
      "/directory/users",
      "/directory/group",
      "/directory/device",
      "/directory/activeDirectory",
      "/linuxMachines/jumpServer",
      "/linuxMachines/addJumpServer",
      "/connections",
      "/connections/createConnection",
      "/endpoint",
      "/sessionRecordings",
      "/credentialRotation",
      "/did/dids",
      "/did/walletUser",
      "/did/verifiableCredentials",
      "/did/transactionLogs",
      "/did/walletUser/view-credentials/:id",
    ],
  },

  ENDUSER: {
    canAccess: ["/endUser/endpoints", "/endUser/connections", "/endUser/assignedVcs"],
  },
};

export const authorize = (role, page, id) => {
  if (id) {
    const access = userRoles[role].canAccess.map((path) => {
      path.replace("dynamicId", id);
    });
    if (access.includes(page)) {
      return true;
    }
    return false;
  } else {
    if (userRoles[role].canAccess.includes(page)) {
      return true;
    } else {
      return false;
    }
  }
};
