import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams,useLocation } from "react-router-dom";
import {
  fetchEndpoints,
  fetchEPMUsers,
  fetchIssuerList,
  fetchWallets,
} from "../../../common/functions";
import { sleep } from "../../../common/sleep";
import { AUTH_TOKEN, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";

export default function ForceResetCredentials() {
  const { epmUserId, instanceId } = useParams();

  const [userInfo, setUserInfo] = useState({});

  const [endpoints, setEndpoints] = useState([]);
  const [endpointUsers, setEndpointUsers] = useState([]);
  const [issuers, setIssuers] = useState([]);
  const [walletUsers, setWalletUsers] = useState([]);

  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const [userPrivilege, setUserPrivilege] = useState("");
  const [selectedTtl, setSelectedTtl] = useState("");
  const [vcExpiry, setVcExpiry] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const epmUsername = queryParams.get("epmuser");
  const instanceName = queryParams.get("instance")
  const epmUserid = queryParams.get("epmuserId");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [credData, setcredData] = useState([]);


  

  const [form] = Form.useForm();

  useEffect(() => {
    Promise.all([
      
      

      fetchWallets().then((wallets) => {
        setWalletUsers(wallets || []);
      }),

      fetchIssuerList().then((issuerList) => {
        setIssuers(issuerList || []);
      }),

      fetchEndpoints({}).then((instances) => {
        console.log(instances);
        setEndpoints(
          instances.map((instance) => ({
            id: instance.instanceId,
            hostName: instance.hostName,
            os: instance.osName,
            status: instance.status,
          })),
        );
      }),
    ]).then(() => {
      setLoadingData(false);
    });

    fetchAssignedCredential();
  }, []);

  const fetchAssignedCredential = async (e) => {

    let pageDetails = {
      walletId: 1,
      pageNumber: paginationParams.current,
      pageSize: paginationParams.pageSize,
      filter: "",
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/credential/GetAssignedCredential`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      setTotalCount(res.data.credentials.length);
     let Data = res.data.credentials.map((cred, i) => ({
        id: cred.id,
        issuerId: cred.issuerId,
        issuerName: cred.issuerName,
        schemaId: cred.schemaId,
        schemaName: cred.schemaName,
        issuedAt: cred.issuedAt,
        credentialId: cred.credentialId,
        expireDate: cred.expireDate,
        name: cred.name,
        status: cred.status,
      }));
      setcredData(Data)

    } catch (err) {
      
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      }
    }
  };

  const doSubmit = async function (userData) {
    setLoadingData(true);
    await sleep(1100);
    navigate(-1);
  };

  return (
    <Spin spinning={loadingData}>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col>
              <h2 className="title">Force Reset Credentials</h2>
            </Col>
          </Row>
          <Form form={form} layout="vertical" onFinish={doSubmit}>
            <Row>
              <Col span={24}>
                <Row align="middle" justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      name="endpoints"
                      label="Endpoint"
                      initialValue={epmUsername}
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoint",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        disabled={true}
                        placeholder="Endpoint User"
                        options={endpoints.map((endpoint) => ({
                          value: endpoint.id,
                          label: endpoint.text,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="priviledged"
                      label="Privileged User"
                      initialValue={false}
                      rules={[
                        {
                          required: true,
                          message: "User Privilege is required",
                        },
                      ]}
                    >
                      <Select
                        onChange={(option) => setUserPrivilege(option)}
                        options={[
                          { label: "True", value: true },
                          { label: "False", value: false },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}></Col>
                </Row>

                <Row align="middle" justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      name="select-ttl"
                      label="Escalation Time Limit"
                      initialValue="No Limit"
                      rules={[
                        {
                          required: true,
                          message: "Select Escalation Time Limit",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "No Limit" },
                          { value: 1, label: "1 Day" },
                          { value: 30, label: "30 Days" },
                          { value: "custom", label: "Enter Custom Value" },
                        ]}
                        labelInValue={true}
                        onChange={(option) => setSelectedTtl(option.label)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="wallet"
                      label="Email to Send SSH Keys or Passwords"
                      rules={[
                        {
                          required: true,
                          message: "Select User Wallet",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        labelInValue={true}
                        optionFilterProp="children"
                        placeholder="Search or Select Email"
                        onChange={(option) => option.label}
                        options={walletUsers.map((walletUser) => ({
                          value: walletUser.email,
                          label: walletUser.email,
                        }))}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}></Col>
                </Row>

                <Row align="middle" justify="space-between">
                  <Col span={8}>
                    {selectedTtl === "Enter Custom Value" && (
                      <Col span={12}>
                        <Form.Item
                          name="select-custom-date"
                          label="Custom Date"
                          rules={[
                            {
                              required: true,
                              message: "Select Expiry",
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={(_date, dateString) =>
                              setVcExpiry(dateString)
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Col>
                  <Col span={8}></Col>

                  <Col span={4}></Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ paddingBlock: 12 }}>
              <Col span={24}>
                <Tabs
                  items={[
                    {
                      key: "endpoints-impacted",
                      label: `Endpoints Impacted`,
                      children: <EndpointsImpacted endpoints={endpoints} />,
                    },
                    {
                      key: "credentials-impacted",
                      label: `Credentials Impacted`,
                      children: <CredentialsImpacted credentials={credData} />,
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "1rem" }}>
              <Col span={20}>
                <Row justify="end">
                  <Col span={2}><Button type="default" onClick={()=>navigate(-1)}>
                    Cancel
                  </Button></Col>
                  <Col>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={4} />
            </Row>
          </Form>
        </Col>
        <Outlet />
      </Row>
    </Spin>
  );
}

function EndpointsImpacted({ endpoints }) {
  return (
    <Row>
      <Col span={20}>
        <Table
          size="small"
          columns={[
            {
              title: "Host Name",
              dataIndex: "hostName",
              key: "host-name",
            },
            {
              title: "OS",
              dataIndex: "os",
              key: "os",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
            },
          ]}
          rowKey="email"
          dataSource={endpoints}
        />
      </Col>
    </Row>
  );
}

function CredentialsImpacted({ credentials }) {
  return (
    <Row>
      <Col span={20}>
        <Table
          size="small"
          columns={[
            {
              title: "Credentials",
              dataIndex: "hostName",
              key: "host-name",
            },
            {
              title: "Schema",
              dataIndex: "os",
              key: "os",
            },
            {
              title: "Created Date",
              dataIndex: "created",
              key: "created",
            },
            {
              title: "Issuer",
              dataIndex: "issuer",
              key: "issuer",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
            },
          ]}
          rowKey="email"
          dataSource={credentials}
        />
      </Col>
    </Row>
  );
}
