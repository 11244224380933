import { useState, useEffect,useRef } from "react";
import { Row, Col, Steps, Button, Input, Alert,Divider, Select,Form,DatePicker,Typography,Modal,Space } from "antd";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL } from "../../constants";
import { DeleteOutlined,PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../styles/Workloadhistory.scss";
import ChatWidget from "./Chatbot";
const { Step } = Steps;
const { Option } = Select;
const { Title, Text } = Typography;
let index = 0;

const Createworkload = () => {
  const [current, setCurrent] = useState(0);
  const [stepOneSecondVisible, setStepOneSecondVisible] = useState(false);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [stepOneOneVisible, setStepOneOneVisible] = useState(true);
  const [idName , setidName] = useState("Authnull Identity");
  const [id , setid] = useState("389fd-43ds1-9c4ts");
  const [AssignedGroup , setAssignedGroup] = useState("Group #2");
  const [type , setType] = useState("User Assigned");
  const [selectedTtl, setSelectedTtl] = useState("1 Day");
  const [selectedRotation, setSelectedRotation] = useState("1 Day");
  const [walletEmail , setWalletemail] = useState("Identity@authnull.com");
  const [selectedidGroup, setselectedidGroup] = useState("");
  const [items, setItems] = useState(['All', 'Authnull Admin','Authnull Identity']);
  const [Groupitems, setGroupItems] = useState([ 'Group #1','Group#2']);
  const [labelsitem, setLabelsitem] = useState([ 'Nis 1.0.1','Nist 1.00']);
  const [groupname, setgroupName] = useState('');
  const [labels, setlabel] = useState('');
  const [name, setName] = useState('');
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value)

  };
  const ongroupChange = (event) => {
    setgroupName(event.target.value)
   
  };
  const onlabelChange = (event) => {
    setlabel(event.target.value)
    

  };
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addGroupItem = (e) => {
    e.preventDefault();
    setGroupItems([...Groupitems, groupname || `New item ${index++}`]);
    setgroupName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

   const addlabels = (e) => {
    e.preventDefault();
    setLabelsitem([...labelsitem, labels || `New item ${index++}`]);
    setlabel('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };


 
  const [form] = Form.useForm();

  useEffect(() => {
    
    
  }, [console.log(selectedidGroup)]);
      
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setselectedidGroup("")

  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setselectedidGroup("")
  };
  return (
    <div className="rule-container">
    <Row style={{paddingRight:"3rem",overflow:"auto"}}>
        <Col span={12}>
          {stepOneOneVisible && (
        <Row  className="content-conatiner">
        <Col span={24}>
         <Row>
          
            <Col span={24}>
              <Row justify={"space-between"}>
                <Col  span={8}><h3 style={{marginTop:"2rem"}}>Lets set this up </h3></Col>
              </Row>
              <Row>
                <Col span={24}>
              <Form 
                    form={form}
                    layout="vertical"
                    
              >
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Workload Identity Name"
                    
                   
                  >
                   <Select
                   mode="multiple"
      placeholder="Search and Select"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder="Please enter Identity name"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add Wokload Identity
            </Button>
          </Space>
        </>
      )}
      options={items.map((item) => ({
        label: item,
        value: item,
      }))}
    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                <Form.Item
                    name="type"
                    label="Type"
                    initialValue={"user assigned"}
                   
                  >
                    <Select
                      placeholder="Select Type"
                      options={[
                        { label: "System Assigned", value: "system assigned" },
                        { label: "User Assigned", value: "user assigned" },
                        
                      ]}
                     
                    />
                  </Form.Item>
                </Col>
                
                
              </Row>
            </Col>
          </Row>
        

          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
              <Col span={24}>
              <Form.Item
                    name="Identitygroup"
                    label="Identity Group"
                   
                  >
                
                <Select
                   mode="multiple"
      style={{
        
      }}
      placeholder="Search and Select"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder="Please enter group"
              ref={inputRef}
              value={groupname}
              onChange={ongroupChange}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addGroupItem}>
              Add Identity Group
            </Button>
          </Space>
        </>
      )}
      options={Groupitems.map((Groupitems) => ({
        label: Groupitems,
        value: Groupitems,
      }))}
    />
                    </Form.Item>
                </Col>
                <Col span={24}>
                <Form.Item
                    name="access"
                    label="Can Access"
                   
                  >
                <Select
                    mode="multiple"
                    allowClear
                      placeholder="Select Identity Groups or Identities"
                      options={[
                        { label: "Storage services", value: "1" },
                        { label: "API access 1", value: "2" },
                        { label: "API access Group 2", value: "3" },
                        
                      ]}
                     
                    />
                    </Form.Item>
                </Col>

              </Row>
            </Col>
          </Row>

          
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
              <Col span={24}>
              <Form.Item
                    name="labels"
                    label="Labels"
                   
                  >
                
                <Select
                   mode="multiple"
      style={{
        
      }}
      placeholder="Search and Select"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder="Please enter label"
              ref={inputRef}
              value={labels}
              onChange={onlabelChange}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addlabels}>
              Add Label
            </Button>
          </Space>
        </>
      )}
      options={labelsitem.map((labelsitem) => ({
        label: labelsitem,
        value: labelsitem,
      }))}
    />
                    </Form.Item>
                </Col>
                

              </Row>
            </Col>
          </Row>

         
         
          <Row justify="end" style={{ marginTop: "1rem" }}>
            <Col span={2}>
                
               <Button type="primary" htmlType="submit" onClick={() => {
                    setStepOneSecondVisible(true);
                    setStepOneOneVisible(false)
                  }}>
                  Save
                </Button>
              
            </Col>
          </Row>
        
        </Form>
        </Col>
              </Row>
              
            </Col>
            
           
          </Row>
          
        </Col>
      </Row>
      )}
          {stepOneSecondVisible && (
            <Row  className="content-conatiner">
            <Col span={24}>
             <Row>
                <Col span={24}>
                  <Row justify={"space-between"}>
                    <Col  span={12}><h3 style={{marginTop:"2rem"}}>Your workload identity is setup </h3></Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                  <Form 
                        form={form}
                        layout="vertical"
                        
                  >
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    
                    <Col span={10}>
                      <Form.Item
                        name="name"
                        label="Name"
                        initialValue={idName}
                        
                        
                        
                       
                      >
                        <Input
                        disabled
                        
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="ID"
                        label="ID"
                        initialValue={id}
                        
                        
                        
                       
                      >
                        <Input
                        disabled
                        
                        />
                      </Form.Item>
                    </Col>
                    
                    
                    
                  </Row>
                </Col>
              </Row>
              
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                  <Col span={10}>
                    <Form.Item
                        name="type"
                        label="Type"
                        initialValue={type}
    
                       
                      >
                        <Input
                          
                          disabled
                         
                        />
                      </Form.Item>
                    </Col>
    
                    <Col span={10}>
                    <Form.Item
                        name="selectUserIdentity"
                        label="Identity Group"
                        initialValue={AssignedGroup}
                        
                        
                        
                       
                      >
                    <Input
                    
                          
                          disabled
                         
                        />
                        </Form.Item>
                    </Col>
    
                  </Row>
                </Col>
              </Row>
    
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                  
                    <Col span={10}>
                    <Form.Item
                        name="access"
                        label="Can Access"
                        initialValue={AssignedGroup}
                        
                        
                        
                       
                      >
                    <Input
                       
                          disabled
                         
                        />
                        </Form.Item>
                    </Col>
    
                  </Row>
                </Col>
              </Row>
              
    
             
              <Row justify="end" style={{ marginTop: "1rem" }}>
                <Col span={4}>
                    
                   <Button type="primary" htmlType="submit">
                   Assign to Wallet
                    </Button>
                  
                </Col>
              </Row>
            
            </Form>
            </Col>
                  </Row>
                </Col>
                
               
              </Row>
            </Col>
          </Row>
          )}
        </Col>
        <Col
          span={12}
          style={{ paddingLeft: "2rem" }}
          className=" add-workloadrule-version"
        >
          <Row className="commit-container">
            <Col className="version-history" span={24}>
              {/* Replace this part with your dynamic version history data */}
                <>
                  <Row>
                    <Col>
                      <h2 className="version-history-title">Assignment History</h2>
                    </Col>
                  </Row>
                  <Col span={24}>
                   
                        <div className="github-commit-card">
                          <div className="commit-header">
                            <p className="commit-info">
                              Last updated by Asif Ali @ 12-12-2023
                            </p>
                          </div>
                          <div className="commit-details">
                            {/* <p className="commit-message">abcc</p> */}
                            <div className="commit-code">
                              <pre className="code-snippet">
                                
                                        <div
                                          
                                          className="code-line"
                                        >
                                          Access: {"Authnull Identity "}
                                         
                                        </div>
                                   
                                <div
                                  
                                  
                                >
                                  
                                  <h5
                                    style={{ marginTop: "0.5rem" }}
                                    className="code-line"
                                  >
                                    Can access :{"Authnullgroup #1 AuthGroup #5"}
                                    
                                  </h5>
                                  
                                </div>
                              </pre>
                            </div>
                          </div>
                        </div>
                  </Col>

                  <Col span={24}>
                   
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 12-12-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       {/* <p className="commit-message">abcc</p> */}
                       <div className="commit-code">
                         <pre className="code-snippet">
                           
                                   <div
                                     
                                     className="code-line"
                                   >
                                     Access: {"Authnull Identity "}
                                    
                                   </div>
                              
                           <div
                             
                             
                           >
                             
                             <h5
                               style={{ marginTop: "0.5rem" }}
                               className="code-line"
                             >
                               Can access :{"Authnullgroup #1 AuthGroup #5"}
                               
                             </h5>
                            
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
             </Col>
             <Col span={24}>
                   
                   <div className="github-commit-card">
                     <div className="commit-header">
                       <p className="commit-info">
                         Last updated by Asif Ali @ 12-12-2023
                       </p>
                     </div>
                     <div className="commit-details">
                       {/* <p className="commit-message">abcc</p> */}
                       <div className="commit-code">
                         <pre className="code-snippet">
                           
                                   <div
                                     
                                     className="code-line"
                                   >
                                     Access: {"Authnull Identity "}
                                    
                                   </div>
                              
                           <div
                             
                             
                           >
                             
                             <h5
                               style={{ marginTop: "0.5rem" }}
                               className="code-line"
                             >
                               Can access :{"Authnullgroup #1 AuthGroup #5"}
                               
                             </h5>
                            
                           </div>
                         </pre>
                       </div>
                     </div>
                   </div>
                   <ChatWidget/>
             </Col>
             
                </>
            </Col>
          </Row>
        </Col>
      </Row>
      </div>
  );
};

export default Createworkload;
