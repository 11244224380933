import { useState, useEffect } from "react";
import { Row, Col, Steps, Button, Input, Alert, Select,Form,DatePicker,Typography, InputNumber } from "antd";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL } from "../../constants";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link } from "react-router-dom";
const { Step } = Steps;
const { Option } = Select;
const { Title, Text } = Typography;

const ServiceWalletAssignment = () => {
  const [current, setCurrent] = useState(0);
  const [stepOneSecondVisible, setStepOneSecondVisible] = useState(false);
  const [stepOneOneVisible, setStepOneOneVisible] = useState(true);
  const [idName , setidName] = useState("Authnull Identity");
  const [id , setid] = useState("389fd-43ds1-9c4ts");
  const [AssignedGroup , setAssignedGroup] = useState("Group #2");
  const [type , setType] = useState("User Assigned");
  const [selectedTtl, setSelectedTtl] = useState("1 Day");
  const [selectedRotation, setSelectedRotation] = useState("1 Day");
  const [walletEmail , setWalletemail] = useState("account@authnull.com");
  const [form] = Form.useForm();


  return (
    <Row>
    <Col span={24}>
      <Row className="content-conatiner">
  <Col span={24}>
    <h3>Who is this assigned to?</h3>
    <Form form={form} layout="vertical" >
      <Row>
        <Col span={24}>
        <Row align="middle" justify="space-between">
            <Col span={8}>
            <Form.Item name="assignedTo" label="Who can access what?">
            <Select
                      defaultValue={['Endpoint groups #1' , 'mysql-admin']}
                      options={[
                        { label: "Endpoint Groups #2", value: "groups #2" },
                        { label: "mysql-admin", value: "admin" },
                        { label: "Endpoint Groups #3", value: "groups #3" },
                        { label: "mysql-authnull", value: "authnull" },
                        { label: "Endpoint Groups #4", value: "groups #4" }, 
                        { label: "mysql-backup", value: "backup" },
                      ]}
                      mode="multiple"
                      disabled
                      allowClear
                    />
              </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item
              name="access"
              label="Can Access"
            >
              <Select
                      defaultValue={['Endpoint groups #2' , 'Endpoint Groups #3']}
                      options={[
                        { label: "Endpoint Groups #2", value: "groups #2" },
                        { label: "mysql-admin", value: "admin" },
                        { label: "Endpoint Groups #3", value: "groups #3" },
                        { label: "mysql-authnull", value: "authnull" },
                        { label: "Endpoint Groups #4", value: "groups #4" }, 
                        { label: "mysql-backup", value: "backup" },
                      ]}
                      mode="multiple"
                      allowClear
                      disabled
                    />
            </Form.Item>
            </Col>
            <Col span={6}>
            </Col>
          </Row>
          <Row align="middle" justify="space-between">
            <Col span={8}>
            <Form.Item name="holdingpermisson" label="Who will hold these permissions?" initialValue={walletEmail}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item
              name="selectRotation"
              label="Assignment Duration"
            
            >
              <Select
              placeholder="Select Duration"
                style={{ width: "100%" }}
                options={[
                  { value: 0, label: "Rotate per use" },
                  { value: 1, label: "1 Day" },
                  { value: 7, label: "1 Week" },
                  { value: 30, label: "30 Days" },
                  { value: "custom", label: "Enter Custom Value" },
                ]}
                labelInValue={true}
                onChange={(option) => setSelectedRotation(option.label)}
              />
            </Form.Item>
            </Col>
            <Col span={6}>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col span={8}>
              <Form.Item
                name="selectTTL"
                label="Rotation Of Credentials"
              >
                <Select
                placeholder="Select Duration"
                  style={{ width: "100%" }}
                  options={[
                    { value: 0, label: "1 Use" },
                    { value: 1, label: "1 Day" },
                    { value: 7, label: "1 Week" },
                    { value: 30, label: "30 Days" },
                    { value: "custom", label: "Enter Custom Value" },
                  ]}
                  labelInValue={true}
                  onChange={(option) => setSelectedTtl(option.label)}
                />
              </Form.Item>
              </Col>
            <Col span={8}>
            {selectedRotation === "Enter Custom Value" && (
              <Form.Item
              name="select-custom-date"
              label="Days"
            >
              <InputNumber
                style={{ width: "100% " }}
                min={3}
                max={30}
              />
            </Form.Item>
            )}
            </Col>
            <Col span={6}></Col>
          </Row>
        </Col>
      </Row>
      <Row align="middle" justify="space-between">
            
            <Col span={8}>
            {selectedTtl === "Enter Custom Value" && (
              <Form.Item
              name="select Custom Date"
              label="Days"
            >
              <InputNumber
                style={{ width: "100% " }}
                min={3}
                max={30}
              />
            </Form.Item>
            )}
            </Col>
            <Col span={8}></Col>
            
             <Col span={6}></Col>
             </Row>
      <Row style={{ marginTop: "1rem" }} justify="space-between">
        <Col></Col>
        <Col span={9}>
          <Button type="primary">
            Next
          </Button>
        </Col>
      </Row>
    </Form>
  </Col>
</Row> 
    </Col>
  </Row>
  );
};

export default ServiceWalletAssignment;