import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Table,
  Tag,
  Button,
  Input,
  notification,
  Cascader,
  Tabs,
  TabsProps,
  Dropdown,
  Breadcrumb,
  Modal,
} from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_DID_URL, REACT_APP_PAM_API } from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";
import { MoreOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useDebounce } from "../../../common/debounce";

const Context = React.createContext({
  name: "Default",
});
const { TabPane } = Tabs;

const EndpointUserDetails = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [search, setSearch] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCredRecord, settotalCredRecord] = useState(1);
  const [CredData, setCredData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const epmUsername = queryParams.get("epmUsername");
  const epmUserId = queryParams.get("epmUserid");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setCredData([]);
    }
  };
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchVerifiableCredential();
  }, [debouncedValue, currentPage]);

  const fetchVerifiableCredential = (e) => {
    let vcRequest = {
      epmUserId: Number(epmUserId),
      pageNumber: paginationParams.current,
      pageSize: paginationParams.pageSize,
      filter: "",
    };

    axios
      .post(`${REACT_APP_API_DID_URL}/credential/getCredentialByEpmUser`, vcRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        settotalCredRecord(res.data.count);
        if (res?.data?.data) {
          let credentialData = res.data.data.map((cred) => ({
            id: cred.id,
            createDate: cred.created_at,
            userName: cred.credential_name,
            issuer: cred.issuer_name,
            schema: cred.user_source,
            status: cred.status,
          }));
          setCredData(credentialData);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Credentials are invalid") {
          logOutUser();
        } else if (err.response.status == 401) {
          logOutUser();
        } else {
        }
      });
  };

  const tab2columns = [
    {
      title: "Credentials",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Schema",
      dataIndex: "schema",
      key: "schema",
    },
    {
      title: "Created Date",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "Issuer",
      dataIndex: "issuer",
      key: "issuer",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  href: "",
                  title: <HomeOutlined />,
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Endpoints Users</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Endpoint Users Details",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">{`Endpoint Users Details - ${epmUsername}`} </h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tab-container">
                <Tabs activeKey={selectedTab}>
                  <TabPane style={{ width: "65rem" }} tab="Credentials" key="0">
                    <Table
                      rowKey="id"
                      dataSource={CredData}
                      columns={tab2columns}
                      pagination={{ ...paginationParams, total: totalCredRecord }}
                      onChange={handleTableChange}
                      loading={isLoading}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointUserDetails;
