import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  notification,
  Row,
  Col,
  message
} from 'antd';
import {
    AUTH_TOKEN,
    REACT_APP_API,
    REACT_APP_API_DID_URL,
    REACT_APP_API_PAM_URL,
    REACT_APP_PAM_API,
  } from "../../../constants";
  
import axios from 'axios';
import { logOutUser } from "../../../common";
import { fetchEndpointGroups, fetchEndpoints } from "../../../common/functions";


const { TextArea } = Input;
const { Option } = Select;

const AddGroupModal = ({ isAddgroup,setIsAddgroup, }) => {
  const [groupName, setGroupName] = useState('');
  const [baseDN, setBaseDN] = useState('');
  const [nameFormat, setNameFormat] = useState('');
  const [groupFormat, setGroupFormat] = useState('');
  const [metaData, setMetaData] = useState('');
  const [validGroupName, setValidGroupName] = useState(true);
  const [validMetaData, setValidMetaData] = useState(true);
  const [validBaseDN, setValidBaseDN] = useState(true);
  const [validInstance, setValidInstance] = useState(true);
  const [instanceData, setInstanceData] = useState([]);
  const [selectedInstancesRow, setSelectedInstancesRow] = useState([]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [formData, setFormData] = useState();




  useEffect(() => {
    fetchEndpoints({}).then((instances) => {
        setInstanceData(
          instances.map((instance) => ({
            id: instance.instanceId,
            text: instance.hostName,
            publicIp: instance.publicIp,
          })),
        );
      });

      setFormData([
        { name: ["groupName"], value: groupName },
        { name: ["endpoints"], value: selectedInstances },
       
      ]);
  }, []);


  const fetchEndpointGroup = async () => {
    let pageDetails = {
      domainId: "1",
      pageId: 1,
      pageSize: 10,
      token: AUTH_TOKEN(),
      filter: search,
      // filter: {
      //   filterBy: "groupName",
      //   value: searchText ? searchText : "",
      // },
    };
    

    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      let Records = res?.data?.totalCount;
      if (res.data.groups) {
       let endpointGroupData = res?.data?.groups?.map((grp, i) => ({
          index: i,
          id: grp?.groupId,
          groupName: grp?.groupName,
          count: grp?.usersCount,
          // credential: grp.CredentialExpiry,
        }));
       
        
      } else {
        
      }
     
    } catch (err) {
      
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
       
      }
    }
  };
  

 

  const handleSaveGroup = (e) => {

      let data = {
        domainId: 1,
        groupName: groupName,
        instanceIds: selectedInstances ?? [],
        ou: groupFormat ?? '',
        cn: nameFormat ?? '',
        dc: baseDN ?? '',
        token: AUTH_TOKEN(),
      };

      axios
        .post(`${REACT_APP_API_PAM_URL}/instanceGroup/addInstanceGroup`, data, {
          headers: {
            'X-Authorization': AUTH_TOKEN(),
            withCredentials: true,
          },
        })
        .then(() => {
          notification.open({
            type: "success",
            content: `Adding Group Successful`,
          });
        })
        .catch((err) => {
          if (err.response.data.message == "Credentials are invalid") {
            notification.open({
              type: "error",
              content: "Credentials are invalid",
            });
            logOutUser();
          } else if (err.response.status == 401) {
            logOutUser();
          } else {
            notification.open({
              type: "error",
              content: "Unable to Add Group",
            });
          }
        });
    };


  const handleOk = () => {
    let pageId = 1
    if (groupName.length) {
        handleSaveGroup()
        fetchEndpointGroup()
       
        }
    setTimeout(() => {
        setIsAddgroup(false);
    }, 3000);
    
  };
  const handleCancel = () => {
    setIsAddgroup(false);
  };
  console.log(selectedInstances)


  return (
    
    <Modal
    open={isAddgroup}
    title={`Add Group`}
    onOk={handleOk}
    onCancel={handleCancel}
    footer={[null]}
    width={800}
  >
    {setNotification}
    <Row>
      <Col span={24}>
      <Form
              form={form}
              layout="vertical"
              onFinish={handleOk}
              fields={formData}
              onFieldsChange={(_, allFields) => {
                setFormData(allFields);
              }}
            >
        <Row className="search-box-container">
          <Col span={24}>
            
            <Form.Item
                        name="groupName"
                        label="Group Name"
                        rules={[
                          {
                            required: true,
                            message: "Group name required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter group name" 
                              onChange={(e) => setGroupName(e.target.value)}
                                        />
                      </Form.Item>
          </Col>
        </Row>
                <Row
                  style={{ marginBottom: "2rem", gap: "1.5rem" }}
                  className="search-box-container"
                >
                  <Col span={24}>
                  <Form.Item
                        name="endpoints"
                        label="Endpoints"
                        rules={[
                          {
                            required: true,
                            message: "Select Endpoints",
                          },
                        ]}
                      >
                
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        mode="multiple"
                        placeholder="Search Endpoints"
                        allowClear
                        onChange={(value) => setSelectedInstances(value)}
                        options={instanceData.map((instanceData) => ({
                            value: instanceData.id,
                            label: instanceData.text,
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          
                        />
                        
                  
                    </Form.Item>

                    
                  </Col>
                </Row>

                <Row>
              <Col span={24}>
              <p className="search-label">Other Metadata    </p>
                <TextArea
                  style={{ width: "100%" }}
                  label="Other Metadata"
                  validateStatus={validMetaData ? '' : 'error'}
                  help={validMetaData ? '' : 'Invalid Other Metadata'}
                  rows={8}
                  value={metaData}
                  onChange={(e) => {
                    setMetaData(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Form.Item style={{ marginTop:"1rem", marginBottom:"0rem", textAlign: "end" }}>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Add
                </Button>
              </Form.Item>
          </Form>
      </Col>
    </Row>
  </Modal>
  );
};

export default AddGroupModal;