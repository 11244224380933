import { Col, Input, Row, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL } from "../../constants";
import { logOutUser } from "../../common";
import moment from "moment-timezone";
import { useDebounce } from "../../common/debounce";

const AssignedVc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [endpointData, setEndpointData] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchEndpoints();
  }, [debouncedValue, paginationParams]);

  const columns = [
    {
      title: "Credential Name",
      dataIndex: "credentialName",
      key: "credentialName",
    },
    {
      title: "Endpoint User",
      dataIndex: "epmUsername",
      key: "epmUsername",
    },
    {
      title: "Endpoint",
      dataIndex: "hostname",
      key: "hostname",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Expiry Date",
      dataIndex: "expirationDate",
      key: "expirationDate",
    },
    {
      title: "Issuer",
      dataIndex: "issuerName",
      key: "issuerName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) => (val.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
  ];

  const fetchEndpoints = () => {
    const userId = localStorage.getItem("userId");

    let data = {
      pageId: paginationParams.currentPage,
      pageSize: paginationParams.pageSize,
      filter: {
        filterBy: "",
        value: "",
      },
      search,
      userId: parseInt(userId),
      domainId: 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAssignCredential`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointData(
          res?.data?.credentials?.map((cred, i) => ({
            id: i + 1,
            ...cred,
            createdAt:
              cred.createdAt &&
              moment(cred.createdAt).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z"),
            expirationDate:
              cred.expirationDate &&
              moment(cred.expirationDate).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z"),
          }))
        );
        setTotalCount(res?.data?.totalCount);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Verifiable Credentials</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={endpointData}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default AssignedVc;
