import { Button, Modal, Row, Col, Input, Table, message, Form, Select } from "antd";
import React, { useState, useEffect } from "react";
import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
} from "../../../constants";
import { fetchEPMUsers, HEADERS } from "../../../common/functions";
import axios from "axios";
import { logOutUser } from "../../../common";

const AddUser = ({ isAddUser, setIsAddUser, notification, fetchUsers }) => {
  const [issuerData, setIssuerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [notification, setNotification] = message.useMessage();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchIssue();
  }, []);
  const handleOk = (e) => {
    console.log(e);
    let payload = {
      ...e,
      domainId: 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/onboardUser`, payload, HEADERS)
      .then((res) => {
        setIsLoading(false);
        notification?.open({
          type: "success",
          content: "User onboarded Successful!",
        });
        setIsAddUser(false);
        fetchUsers();
      })
      .catch((err) => {
        setIsLoading(false);
        let errMgs = err.response.data.message;
        notification.open({
          type: "error",
          content:
            errMgs === "User already exists"
              ? "User already exists"
              : errMgs === "Invalid credentials"
              ? "Credentials are invalid"
              : "Unable to unboard User",
        });
        if (errMgs === "Invalid credentials") {
          logOutUser();
        }
      });
  };
  const handleCancel = () => {
    setIsAddUser(false);
  };

  const fetchIssue = () => {
    let payload = {
      domainId: 1,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIssuerData(
          res?.data?.map((data) => ({
            value: data.IssuerId,
            label: data.Name,
          }))
        );
      });
  };

  const handleRole = (_, data) => {
    form.setFieldsValue({
      userRoleId: data.role,
    });
  };
  const handleIssuer = (value) => {
    form.setFieldsValue({
      issuerId: value,
    });
  };

  return (
    <Modal
      open={isAddUser}
      title="Add User"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={900}
    >
      <div style={{ marginTop: "2rem" }}>
        <Row>
          <Col span={24}>
            <Form form={form} layout="vertical" onFinish={handleOk}>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "First name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter first name" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="LastName"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "Last name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Last name" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="userRoleId"
                        label="Role"
                        rules={[
                          {
                            required: true,
                            message: "Select role",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleRole}
                          placeholder="Select role"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={[
                            {
                              value: "0",
                              label: "Enduser",
                              role: 3,
                            },
                            { value: "1", label: "Admin", role: 1 },
                          ]}
                        />{" "}
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="phone"
                        label="User Phone"
                        rules={[
                          {
                            required: true,
                            message: "Phone number is required",
                          },
                          {
                            pattern: /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
                            message: "Please enter a valid phone number",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Phone number" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="email"
                        label="Email ID"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Email ID is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:abc@gmail.com" />
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        name="cn"
                        label="Common Name"
                        rules={[
                          {
                            required: true,
                            message: "Common Name is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:cn" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="dn"
                        label="Distinguish Name"
                        rules={[
                          {
                            required: true,
                            message: "Distinguish Name is required",
                          },
                          {
                            pattern: /^(dc=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+)$/,
                            message: "Distinguish Name must be dc=example,dc=com format",
                          },
                        ]}
                      >
                        <Input placeholder="eg:dc=example,dc=com" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="issuerId"
                        label="Issuer DID"
                        rules={[
                          {
                            required: true,
                            message: "Select issuer DID",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleIssuer}
                          placeholder="Select issuer DID"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={issuerData}
                        />{" "}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "end" }}>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Add
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddUser;
