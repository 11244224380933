import { Col, Row, Select, Table, Dropdown, Button, Input, message, Space, Alert, Divider, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API } from "../../constants";
import axios from "axios";
import { logOutUser } from "../../common";
import { MoreOutlined } from "@ant-design/icons";


const ServiceAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [endpointData, setEndpointData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [isAssignUser, setIsAssignUser] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [isLocalGroup, setisLocalGroup] = useState(false);
  const [selectedEndpoints, setSelectedEndpoints] = useState(null);
  const [isAuthFlow, setIsAuthFlow] = useState(false);
  const [isTabChange, setIsTabChange] = useState(false);
  const [ishistory, setIshistory] = useState(true);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });

  const tabChange = false;

  useEffect(() => {

  });

  
  const columns = [
    {
      title: "Service Account Name",
      dataIndex: "serviceAccountName",
      key: "serviceAccountName",
    },
    { title: "Service Account ID", 
      dataIndex: "serviceAccountID", 
      key: "serviceAccountID" 
    },
    {
      title: "Endpoint Groups",
      dataIndex: "endpointGroups",
      key: "endpointGroups",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      
    },
    {
      title: "Assigned to Wallet",
      dataIndex: "assignedToWallet",
      key: "assignedToWallet",
    },
    {
      title: "Assignment History",
      dataIndex: "assignmentHistory",
      key: "assignmentHistory",
    },
    {
      title: "Rules",
      dataIndex: "rules",
      key: "rules",
    },
  ];
  const data1source = [
    {
      serviceAccountName: "mysql-admin",
      serviceAccountID: "3333a5555b8888c0000d",
      endpointGroups: <Tag color="blue">Endpoint-Group1</Tag>,
      access: [<Tag color="blue">Endpoint-Group1</Tag>,
      <Tag color="blue">Endpoint-Group2</Tag>,],
      assignedToWallet: "account@authnull.com",
      assignmentHistory: <Link to={`/serviceAccount/addServiceAccount`}>View history</Link>,
      rules: <Link to={`/serviceAccount/addServiceAccount?history=${true}`} onClick={()=>setIshistory(true)}>View Rules</Link>,
    },
    {
      serviceAccountName: "mysql-authnull",
      serviceAccountID: "6666c9999b7777e3333f",
      endpointGroups: <Tag color="blue">Endpoint-Group1</Tag>,
      access: [<Tag color="blue">Endpoint-Group1</Tag>,
      <Tag color="blue">Endpoint-Group2</Tag>,],
      assignedToWallet: "account@authnull.com",
      assignmentHistory: <Link to={`/serviceAccount/addServiceAccount`}>View history</Link>,
rules: <Link to={`/serviceAccount/addServiceAccount?history=${true}`} onClick={()=>setIshistory(true)}>View Rules</Link>,
    },
    {
      serviceAccountName: "mysql-kloudone",
      serviceAccountID: "2222d1111a7777c4444d",
      endpointGroups: <Tag color="blue">Endpoint-Group1</Tag>,
      access: [<Tag color="blue">Endpoint-Group1</Tag>,
      <Tag color="blue">Endpoint-Group2</Tag>,],
      assignedToWallet: "account@authnull.com",
      assignmentHistory: <Link to={`/serviceAccount/addServiceAccount`}>View history</Link>,
rules: <Link to={`/serviceAccount/addServiceAccount?history=${true}`} onClick={()=>setIshistory(true)}>View Rules</Link>,
    },
    {
      serviceAccountName: "mysql-server",
      serviceAccountID: "2222a6666d8888c9999d",
      endpointGroups: <Tag color="blue">Endpoint-Group1</Tag>,
      access: [<Tag color="blue">Endpoint-Group1</Tag>,
      <Tag color="blue">Endpoint-Group2</Tag>,],
      assignedToWallet: "account@authnull.com",
      assignmentHistory: <Link to={`/serviceAccount/addServiceAccount`}>View history</Link>,
rules: <Link to={`/serviceAccount/addServiceAccount?history=${true}`} onClick={()=>setIshistory(true)}>View Rules</Link>,
    },
  ]

  const filterOption = [
    {
      label: "Endpoint Groups",
      value: "endpointGroups",
    },
    {
      label: "Service Account Name",
      value: "serviceAccountName",
    },
  ];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Service Account</h2>
            </Col>
            <Col>
              <Link to={"/serviceAccount/addServiceAccount"}>
                <Button type="primary">Add Service Account</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      <div>
                        <Select
                          showSearch
                          allowClear
                          placeholder="Filter by"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={(_, data) => {
                            setFilter(data ? data?.value : "");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          options={filterOption}
                        />
                      </div>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={data1source}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      
    </>
  );
};

export default ServiceAccount;
