import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Select,
  Table,
  Modal,
  Input,
  message,
  Cascader,
  Tag,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Link, Outlet } from "react-router-dom";
import { fetchEndpointGroups, fetchEndpoints } from "../../../common/functions";
import AddGroupModal from "./AddEndpointGroup";
import AddUsertogroup from "./AddEpmUserGroup";
import EditEpmGroupModal from "./EditEndpointGroup";
import EpmGroupAuthflow from "./EpmGroupAuthflow";

import { AUTH_TOKEN, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";

export default function EndpointGroups() {
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [endpointFilter, setEndpointFilter] = useState("");
  const [endpoints, setEndpoints] = useState([]);
  const [isAddgroup, setIsAddgroup] = useState(false);
  const [search, setSearch] = useState("");
  const [IsaddUsertogroup, setIsaddUsertogroup] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState(null);
  const [totalRecords, setTotalRecords] = useState(1);
  const [IsGroupauthflow, setIsGroupauthflow] = useState(false);
  const [isEditgroup, setIsEditgroup] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRowKeys:", selectedRowKeys, "selectedRows: ", selectedRows);
      setSelectedRowKeys(selectedRowKeys, selectedRows);
    },
    onSelect: (e, ef) => {
      console.log(e, ef);
    },
  };

  useEffect(() => {
    fetchInstance();
  }, []);

  useEffect(() => {
    setPaginationParams({ ...paginationParams, current: 1 });
  }, [endpointFilter, debouncedValue, debouncedValue]);

  useEffect(() => {
    fetchEndpointGroup();
  }, [paginationParams, filteredValue]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpointGroups([]);
    }
  };

  const fetchEndpointGroup = async () => {
    let pageDetails = {
      domainId: "1",
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      token: AUTH_TOKEN(),
      search,
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
    };
    setLoadingData(true);
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      setEndpointGroups(res?.data?.groups);
      setTotalRecords(res?.data?.totalCount);
      setLoadingData(false);
    } catch (err) {
      if (
        err?.response?.data?.message === "Credentials are invalid" ||
        err?.response?.status === 401
      ) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };

  const deleteEndpointGroup = async (record) => {
    let id = [];
    id.push(Number(record.groupId));
    console.log(record);
    let data = {
      domainId: 1,
      id: id,
      token: AUTH_TOKEN(),
    };
    let url = `${REACT_APP_PAM_API}/instanceGroup/deleteEndpointGroup`;
    try {
      await axios.delete(url, {
        data: data,
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });

      await fetchEndpointGroup();
      notification.open({
        type: "success",
        content: `Endpoint Group Deleted`,
      });
    } catch (err) {
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
        notification.open({
          type: "error",
          content: `Credentials are invalid`,
        });
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: `Error Deleting Endpoint Group`,
        });
      }
    } finally {
      fetchEndpointGroup();
    }
  };

  const fetchInstance = () => {
    let pageDetails = {
      domainId: 1,
      pageId: 1,
      pageSize: 100,
      search,
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };

    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoints(
          res.data.instances.map((instance) => ({
            value: instance.instanceId,
            label: instance.hostName,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
  };

  const option = [
    {
      value: "endpoints",
      label: "Endpoints",
      children: endpoints,
    },
  ];

  return (
    <Row className="content-conatiner">
      {setNotification}
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">Endpoint Groups</h2>
          </Col>

          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsAddgroup(true);
              }}
            >
              Add Group
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Row
                  style={{ marginBottom: "2rem", gap: "1.5rem" }}
                  className="search-box-container"
                >
                  <Col span={6}>
                    <p className="search-label">Filter by</p>

                    <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Search</p>
                    <div>
                      <Input
                        allowClear
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      rowSelection={rowSelection}
                      rowKey="id"
                      dataSource={endpointGroups}
                      pagination={{ ...paginationParams, total: totalRecords }}
                      onChange={handleTableChange}
                      loading={loadingData}
                      columns={[
                        {
                          title: "Group Name",
                          key: "groupName",
                          render: (_value, record) => (
                            <Link
                              to={`/endpointGroups/endpointGroupDetails?groupName=${record.groupName}&groupId=${record.groupId}`}
                            >
                              {record.groupName}
                            </Link>
                          ),
                        },
                        {
                          dataIndex: "hostName",
                          title: "Endpoints",
                          key: "hostName",
                          render: (tags, record) => (
                            <span>
                              <Tag color="blue">{tags}</Tag>
                            </span>
                          ),
                        },
                        {
                          title: "User Count",
                          key: "usersCount",
                          dataIndex: "usersCount",
                        },
                        {
                          title: "Actions",
                          key: "action",
                          render: (_value, record) => (
                            <Dropdown
                              placement="bottomLeft"
                              menu={{
                                items: [
                                  {
                                    key: "add-user",
                                    label: (
                                      <Link
                                        onClick={() => {
                                          setSelectedRecords(record);
                                          setIsaddUsertogroup(true);
                                        }}
                                      >
                                        Add User to Group
                                      </Link>
                                    ),
                                  },
                                  {
                                    key: "auth-flow",
                                    label: (
                                      <Link
                                        onClick={() => {
                                          setSelectedRecords(record);
                                          setIsGroupauthflow(true);
                                        }}
                                      >
                                        Auth Flow
                                      </Link>
                                    ),
                                  },
                                  {
                                    key: "edit-group",
                                    label: (
                                      <Link
                                        onClick={() => {
                                          setSelectedRecords(record);
                                          setIsEditgroup(true);
                                        }}
                                      >
                                        Edit Group
                                      </Link>
                                    ),
                                  },
                                  {
                                    key: "delete-group",
                                    label: (
                                      <Link
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          deleteEndpointGroup(record);
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    ),
                                  },
                                ],
                              }}
                            >
                              <Button type="text" shape="circle" icon={<MoreOutlined />} />
                            </Dropdown>
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Outlet />
      {isAddgroup && (
        <AddGroupModal
          isAddgroup={isAddgroup}
          setIsAddgroup={setIsAddgroup}
          fetchepmGroups={fetchEndpointGroups}
        />
      )}

      {IsaddUsertogroup && (
        <AddUsertogroup
          IsaddUsertogroup={IsaddUsertogroup}
          setIsaddUsertogroup={setIsaddUsertogroup}
          selectedRecords={selectedRecords}
        />
      )}

      {isEditgroup && (
        <EditEpmGroupModal
          isEditgroup={isEditgroup}
          setIsEditgroup={setIsEditgroup}
          selectedRecords={selectedRecords}
          fetchepmGroups={fetchEndpointGroup}
        />
      )}

      {IsGroupauthflow && (
        <EpmGroupAuthflow
          IsGroupauthflow={IsGroupauthflow}
          setIsGroupauthflow={setIsGroupauthflow}
          selectedRecords={selectedRecords}
        />
      )}
    </Row>
  );
}
