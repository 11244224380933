// import "./styles/index.scss";
import { useState } from "react";
import { Avatar, Layout, Menu, Popover, Tooltip } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import Users from "./directory/users/Users";
import {
  ControlOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  RadarChartOutlined,
  SolutionOutlined,
  LogoutOutlined,
  IdcardOutlined,
  KeyOutlined,
  DesktopOutlined,
} from "@ant-design/icons/lib/icons";
import { logOutUser } from "../common";
import Endpoints from "./endUser/Endpoints";
import MyConnections from "./endUser/MyConnections";
import AssignedVc from "./endUser/AssignedVc";
import Groups from "./directory/groups/Groups";
import Devices from "./directory/Devices";
import ActiveDirectory from "./directory/activeDirectory/ActiveDirectory";
import Jumpserver from "./privilegedAccessManagement/jumpServers/Jumpserver";
import AddJumpServer from "./privilegedAccessManagement/jumpServers/AddJumpServer";
import EndpointUsers from "./privilegedAccessManagement/endpointsUsers/EndpointUsers";
import AssignEndpointUser from "./privilegedAccessManagement/endpointsUsers/AssignEndpointUser";
import SessionRecordings from "./privilegedAccessManagement/sessionRecordings/SessionRecordings";
import Connection from "./privilegedAccessManagement/connections/Connection";
import CreateConnection from "./privilegedAccessManagement/connections/CreateConnection";
import PAMEndpoint from "./privilegedAccessManagement/endpoints/Endpoints";
import CheckoutUser from "./privilegedAccessManagement/endpointsUsers/CheckoutUser";
import EndpointGroups from "./privilegedAccessManagement/endpointGroups/EndpointGroups";
import ViewCredentials from "./privilegedAccessManagement/endpointsUsers/ViewCredentials";
import { AddEndpoint } from "./privilegedAccessManagement/endpoints/AddEndpoint";
import CredentialRotationPolicy from "./privilegedAccessManagement/endpointsUsers/CredentialRotationPolicy";
import AddEndpointUser from "./privilegedAccessManagement/endpointsUsers/AddEndpointUser";
import ForceResetCredentials from "./privilegedAccessManagement/endpointsUsers/ForceResetCredentials";
import CredentialRotation from "./privilegedAccessManagement/credentialRotation/CredentialRotation";
import AddPasswordPolicy from "./privilegedAccessManagement/credentialRotation/AddPasswordPolicy";
import AddSshKeyPolicy from "./privilegedAccessManagement/credentialRotation/AddSshKeyPolicy";
import PasswordPolicies from "./privilegedAccessManagement/endpoints/PasswordPolicies";
import DidDashboard from "./did/dashboard";
import DIDS from "./did/dids";
import WalletUsers from "./did/walletUsers";
import Dashboard from "./dashboard";
import Credential from "./did/credentials";
import VerifiableCredential from "./did/verifiableCredential";
import TransactionLog from "./did/transactionLogs";
import EndpointGroupDetails from "./privilegedAccessManagement/endpointGroups/EndpointGroupDetails";
import EndpointDetails from "./privilegedAccessManagement/endpoints/EndpointDetails";
import AddDID from "./did/addUserDID";
import AddHolderrDID from "./did/addHolderDID";
import AddUserDID from "./did/addUserDID";
import UserDetails from "./directory/UserDetails";
import UserDetailsGroups from "./directory/UserDetailsGroups";
import UserDetailsProfile from "./directory/UserDetailsProfile";
import EndpointUserDetails from "./privilegedAccessManagement/endpointsUsers/EndpointUserDetails";
import AddDirectory from "./directory/activeDirectory/addDirectory";
import Step from "./directory/activeDirectory/step";
import ADStep from "./directory/activeDirectory/step";
import ServiceAccount from "./serviceAccount/ServiceAccount";
import AddServiceAccount from "./serviceAccount/AddServiceAccount";
import AddWorkloadIdentity from "./workloadIdentity/AddworkloadIdentity";
import WorkloadIdentity from "./workloadIdentity/workloadIdentity";
import WorkloadConditionalAccess from "./workloadIdentity/WorkloadConditionalaccess";
import { REACT_APP_URL } from "../constants";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
function PrimaryLayout() {
  const navigate = useNavigate();
  const [userRole] = useState(localStorage.getItem("UserRole"));
  const [userName] = useState(localStorage.getItem("UserName"));
  const [openKeys, setOpenKeys] = useState([]);

  const handleNavigation = (path) => {
    if (localStorage.getItem("token")) {
      navigate(path);
    } else {
      logOutUser();
    }
  };
  const handleSubMenuOpenChange = (keys) => {
    // Ensure only one submenu is open at a time
    if (keys.length > 1) {
      setOpenKeys([keys.pop()]); // Keep the last opened submenu
    } else {
      setOpenKeys(keys);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }} className="app-container">
      <Header className="header-container">
        <h1 className="logo-container">
          <RadarChartOutlined />
          Passwordless
        </h1>

        <Popover
          placement="bottom"
          title={localStorage.getItem("UserName")}
          content={
            <a onClick={() => logOutUser()}>
              <LogoutOutlined /> Log Out
            </a>
          }
        >
          <Avatar size="large" style={{ background: "#fff", color: "#000" }}>
            {userName[0].toLocaleUpperCase()}
          </Avatar>
        </Popover>
      </Header>
      <Layout className="body-container" style={{ flex: 1 }} hasSider>
        <Sider width={280} className="left-menu-container">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            openKeys={openKeys} // Set the openKeys to maintain open submenus
            onOpenChange={handleSubMenuOpenChange} // Handle submenu open changes
            style={{
              borderRight: 0,
            }}
          >
            {userRole === "ADMIN" ? (
              <>
                <Menu.Item
                  icon={<DashboardOutlined />}
                  key="dashboard"
                  onClick={() => handleNavigation("/")}
                >
                  Dashboard
                </Menu.Item>

                <SubMenu
                  key="directory"
                  icon={<SolutionOutlined />}
                  title="Directory"
                >
                  <Menu.Item
                    key="users"
                    onClick={() => handleNavigation("/directory/users")}
                  >
                    Users
                  </Menu.Item>
                  <Menu.Item
                    key="groups"
                    onClick={() => handleNavigation("/directory/groups")}
                  >
                    Groups
                  </Menu.Item>
                  {/* <Menu.Item key="devices" onClick={() => handleNavigation("/directory/devices")}>
                    Devices
                  </Menu.Item> */}
                  <Menu.Item
                    key="activeDirectory"
                    onClick={() =>
                      handleNavigation("/directory/activeDirectory")
                    }
                  >
                    Active Directory
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="privileged-access-management"
                  icon={<ControlOutlined />}
                  title="PAM"
                >
                  <Menu.ItemGroup
                    key="pam"
                    title="Privileged Access Management"
                  >
                    <Menu.Item
                      key="endpointsUsers"
                      onClick={() => handleNavigation("/endpointsUsers")}
                    >
                      Endpoints Users
                    </Menu.Item>
                    <Menu.Item
                      key="endpointsGroups"
                      onClick={() => handleNavigation("/endpointGroups")}
                    >
                      Endpoints Groups
                    </Menu.Item>
                    <Menu.Item
                      key="endpoints"
                      onClick={() => handleNavigation("/endpoint")}
                    >
                      Endpoints
                    </Menu.Item>
                    <Menu.Item
                      key="jumpServers"
                      onClick={() =>
                        handleNavigation("/linuxMachines/jumpServer")
                      }
                    >
                      Jump Servers
                    </Menu.Item>
                    <Menu.Item
                      key="connections"
                      onClick={() => handleNavigation("/connections")}
                    >
                      Connections
                    </Menu.Item>
                    <Menu.Item
                      key="sessionRecording"
                      onClick={() => handleNavigation("/sessionRecordings")}
                    >
                      Session Recording
                    </Menu.Item>
                    <Menu.Item
                      key="credentialRoration"
                      onClick={() => handleNavigation("/credentialRotation")}
                    >
                      Credential Rotation
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>
                <SubMenu key="did" icon={<CreditCardOutlined />} title="DID">
                  <Menu.Item
                    key="diddashboard"
                    onClick={() => handleNavigation("/did")}
                  >
                    Dashboard
                  </Menu.Item>
                  <Menu.Item
                    key="dids"
                    onClick={() => handleNavigation("/did/dids")}
                  >
                    DIDs
                  </Menu.Item>
                  <Menu.Item
                    key="WalletUsers"
                    onClick={() => handleNavigation("/did/walletUser")}
                  >
                    Wallet Users
                  </Menu.Item>
                  <Menu.Item
                    key="verifiableCredentials"
                    onClick={() =>
                      handleNavigation("/did/verifiableCredentials")
                    }
                  >
                    Verifiable Credentials
                  </Menu.Item>
                  <Menu.Item
                    key="transactionLogs"
                    onClick={() => handleNavigation("/did/transactionLogs")}
                  >
                    Transaction Logs
                  </Menu.Item>
                </SubMenu>

                {REACT_APP_URL === "https://dev.client.did.authnull.com/" && (
                  <SubMenu
                    key="workload"
                    icon={<IdcardOutlined />}
                    title="Workload Identity"
                  >
                    <Menu.Item
                      key="workloadIdentity"
                      onClick={() => handleNavigation("/workloadIdentity")}
                    >
                      Workload Identity
                    </Menu.Item>
                  </SubMenu>
                )}
                {REACT_APP_URL === "https://dev.client.did.authnull.com/" && (
                  <SubMenu
                    key="serviceAccount"
                    icon={<CreditCardOutlined />}
                    title="Service Account"
                  >
                    <Menu.Item
                      key="showServiceAccount"
                      onClick={() => handleNavigation("/serviceAccount")}
                    >
                      Service Account
                    </Menu.Item>
                  </SubMenu>
                )}
              </>
            ) : (
              <>
                <Menu.Item
                  icon={<DesktopOutlined />}
                  key="endpoints"
                  onClick={() => handleNavigation("/endUser/endpoints")}
                >
                  Endpoints
                </Menu.Item>
                <Menu.Item
                  icon={<DashboardOutlined />}
                  key="myConnections"
                  onClick={() => handleNavigation("/endUser/connections")}
                >
                  Connections
                </Menu.Item>
                <Menu.Item
                  icon={<KeyOutlined />}
                  key="assignedVC's"
                  onClick={() => handleNavigation("/endUser/assignedVcs")}
                >
                  Assigned VC's
                </Menu.Item>
              </>
            )}
          </Menu>
        </Sider>
        <Content className="page-container">
          <Routes>
            {userRole === "ADMIN" ? (
              <>
                <Route path="" element={<Dashboard />} />
                <Route path="directory/*">
                  <Route path="users" element={<Users />} />
                  <Route path="groups" element={<Groups />} />
                  <Route path="devices" element={<Devices />} />
                  <Route
                    path="activeDirectory/addDirectory/step"
                    element={<ADStep />}
                  />
                  <Route
                    path="activeDirectory/addDirectory"
                    element={<AddDirectory />}
                  />
                  <Route path="activeDirectory" element={<ActiveDirectory />} />

                  <Route path="userDetails" element={<UserDetails />} />
                  <Route
                    path="userDetailsGroups"
                    element={<UserDetailsGroups />}
                  />
                  <Route
                    path="userDetailsProfile"
                    element={<UserDetailsProfile />}
                  />
                </Route>
                <Route path="linuxMachines/*">
                  <Route path="jumpServer" element={<Jumpserver />} />
                  <Route path="addJumpServer" element={<AddJumpServer />} />
                </Route>
                <Route path="endpoint/*">
                  <Route path="" element={<PAMEndpoint />} />
                  <Route path="addEndpoint" element={<AddEndpoint />} />
                  <Route path="endpointDetails" element={<EndpointDetails />} />
                  <Route
                    path="passwordPolicies"
                    element={<PasswordPolicies />}
                  />
                </Route>

                <Route path="connections/*">
                  <Route path="" element={<Connection />} />
                  <Route path=":publicIpAddress" element={<Connection />} />
                  <Route
                    path="createConnection"
                    element={<CreateConnection />}
                  />
                </Route>
                <Route
                  path="endpointsUsers/add"
                  element={<AddEndpointUser />}
                />
                <Route
                  path="endpointsUsers"
                  element={<EndpointUsers />}
                ></Route>
                <Route
                  path="endpointuserDetails"
                  element={<EndpointUserDetails />}
                />

                <Route
                  path="checkoutUser/:epmUserId"
                  element={<CheckoutUser />}
                />
                <Route
                  path="rotation/:instanceId/:epmUserId"
                  element={<CredentialRotationPolicy />}
                />
                <Route
                  path="reset-credentials"
                  element={<ForceResetCredentials />}
                />
                <Route
                  path="view-credentials/:instanceId/:epmUserId"
                  element={<ViewCredentials />}
                />
                <Route path="endpointGroups/*">
                  <Route path="" element={<EndpointGroups />} />
                  <Route
                    path="endpointGroupDetails"
                    element={<EndpointGroupDetails />}
                  />
                </Route>
                <Route
                  path="sessionRecordings/:id"
                  element={<SessionRecordings />}
                />

                <Route path="credentialRotation/*">
                  <Route path="" element={<CredentialRotation />} />
                  <Route path="addPassword" element={<AddPasswordPolicy />} />
                  <Route path="addSshKeys" element={<AddSshKeyPolicy />} />
                </Route>
                <Route path="sessionRecordings/*">
                  <Route path="" element={<SessionRecordings />} />
                </Route>
                <Route path="did/*">
                  <Route path="" element={<DidDashboard />} />
                  <Route path="dids" element={<DIDS />} />
                  <Route path="addUserDID" element={<AddUserDID />} />
                  <Route path="addHolderDID" element={<AddHolderrDID />} />
                  <Route path="walletUser" element={<WalletUsers />} />
                  <Route
                    path="walletUser/view-credentials"
                    element={<Credential />}
                  />
                  <Route
                    path="verifiableCredentials"
                    element={<VerifiableCredential />}
                  />
                  <Route path="transactionLogs" element={<TransactionLog />} />
                </Route>

                <Route path="serviceAccount/*">
                  <Route path="" element={<ServiceAccount />} />
                  <Route
                    path="addServiceAccount"
                    element={<AddServiceAccount />}
                  />
                </Route>
                <Route path="workloadIdentity/*">
                  <Route path="" element={<WorkloadIdentity />} />
                  <Route
                    path="addWorkloadIdentity"
                    element={<AddWorkloadIdentity />}
                  />
                  <Route
                    path="workloadConditionalaccess"
                    element={<WorkloadConditionalAccess />}
                  />
                </Route>
              </>
            ) : (
              <Route path="endUser/*">
                <Route path="endpoints" element={<Endpoints />} />
                <Route path="endpointUsers" element={<EndpointUsers />} />
                <Route path="connections" element={<MyConnections />} />
                <Route path="assignedVcs" element={<AssignedVc />} />
              </Route>
            )}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default PrimaryLayout;
