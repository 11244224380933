import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Table,
  Tag,
  Button,
  Input,
  notification,
  Cascader,
  Tabs,
  TabsProps,
  Dropdown,
  Breadcrumb,
  Modal,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API } from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";
import { MoreOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { fetchEndpoints, fetchEPMUsers, fetchEndpointGroups } from "../../../common/functions";
import { useDebounce } from "../../../common/debounce";

const Context = React.createContext({
  name: "Default",
});
const { TabPane } = Tabs;

const EndpointGroupDetails = (isTabChange, setIsTabChange) => {
  const [instaceDataById, setinstaceDataById] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [endpoints, setEndpoints] = useState([]);
  const [instance, setInstance] = useState([]);
  const [epmUser, setEpmUser] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("0");
  const [endpointData, setEndpointData] = useState([]);
  const [endpointUsers, setEndpointUsers] = useState([]);
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [totalUsers, setTotalUsers] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [isOpenPAM, setisOpenPAM] = useState(false);
  const navigate = useNavigate();
  const PAGESIZE = 10;
  let userEmail = localStorage.getItem("UserName");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupName = queryParams.get("groupName");
  const groupId = queryParams.get("groupId");

  const [notify, contextHolder] = notification.useNotification();
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpoints([]);
    }
  };

  const openNotification = (placement) => {
    notify.info({
      message: `Notification ${placement}`,
      description: <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>,
      placement,
    });
  };
  let endpointId = [];
  // let found;

  useEffect(() => {
    handleTab();
    fetchEPMUsers();
    fetchInstance();
    fetchEndpoints({ search }).then((instances) => {
      setInstance(
        instances.map((instance) => ({
          id: instance.instanceId,
          text: instance.hostName,
          publicIp: instance.publicIp,
        }))
      );
    });
    fetchInstanceGroupDetail();
  }, [debouncedValue, isTabChange, currentPage, paginationParams]);

  const fetchInstanceGroupDetail = async () => {
    let payload = {
      pageId: 1,
      pageSize: 10,
      search: search,
      filter: {
        filterBy: "",
        value: "",
      },
      domainId: 1,
      groupId: Number(groupId),
      token: AUTH_TOKEN(),
    };

    let config = {
      headers: {
        "X-Authorization": AUTH_TOKEN(),
        withCredentials: true,
      },
    };
    let res;
    try {
      res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/fetchInstanceGroupDetail`,
        payload,
        config
      );

      let insData = res.data.InstanceGroup.map((ins, i) => ({
        id: ins.instanceId,
        hostName: ins.hostName,
        instanceName: ins.instanceName,
        os: ins.os,
        privateIp: ins.privateIp,
        publicIp: ins.publicIp,
        state: ins.state,
      }));
      console.log(insData);
      setinstaceDataById(insData);
      setTotalUsers(res.data.totalCount);
    } catch (err) {
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    } finally {
      // selectedInstancesRow = new Array(...selectedInstancesRow); // to trigger the change
    }
  };
  const fetchInstance = (record) => {
    let payload = {
      domainId: 1,
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpoints(
          res?.data?.instances.map((ins) => ({
            instance: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIpAddress,
          }))
        );
      });
  };

  const fetchEPMUsers = () => {
    let pageDetails = {
      domainId: 1,
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: [],
      filter: {
        filterBy: "",
        value: "",
      },
      search: search,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res, totalCount) => {
        setIsLoading(false);
        setEpmUser(res?.data?.epmUsers);
        setTotalRecords(res.data.totalCount);
        fetchInstance();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Credentials are invalid") {
          logOutUser();
        } else {
        }
      });
  };

  const handleStatus = async (record) => {
    let data = {
      domainId: 1,
      status: record.state === "Active" ? "Inactive" : "Active",
      instanceIds: [Number(record.id)],
      token: AUTH_TOKEN(),
    };
    console.log(record);
    let url = `${REACT_APP_PAM_API}/instances/updateInstanceStatus`;
    try {
      await axios.put(url, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      await fetchInstance();
      notification.open({
        type: "success",
        content: `${record.hostName} is ${
          record.state === "Active" ? "deactivated" : "activated"
        } successfull`,
      });
    } catch (err) {
      if (err.response.data.message === "Credentials are invalid") {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      }
    }
  };

  const option = [
    {
      value: "endpoint",
      label: "Endpoints",
      children: endpoints,
    },
    {
      value: "endpointUser",
      label: "Endpoint Users",
      children: epmUser,
    },
  ];
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleTab = (key, isTabChange) => {
    if (isTabChange == true) {
      setSelectedTab("1");
    } else {
      setSelectedTab(key);
    }
  };

  const Authcolum = [
    {
      title: "First Factor",
      dataIndex: "firstfactor",
      key: "firstfactor",
    },
    {
      title: "Second Factor",
      dataIndex: "secondfactor",
      key: "secondfactor",
    },
    {
      title: "Third Factor",
      dataIndex: "thirdFactor",
      key: "thirdfactor",
    },
    {
      title: "PAM Code",
      dataIndex: "pamcode",
      key: "pamcode",
    },
  ];

  const tab1columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      dataIndex: "instances",
      title: "Endpoints",
      key: "instances",
      render: (tags) => (
        <span>
          {tags.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Credentials",
      dataIndex: "countOfCredentials",
      key: "countOfCredentials",
    },
    {
      title: "Action",
      key: "action",
      render: (_value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link to={`/endpointsUsers/${record.value}/${record.userName}/assign`}>
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/sessionRecordings/${record.userId}`}>View Session Recordings</Link>
                ),
              },

              {
                key: "checkout-user",
                label: (
                  <Link to={`/checkoutUser/${record.instance}/${record.userId}`}>
                    Checkout User
                  </Link>
                ),
              },

              {
                key: "reset-credential",
                label: (
                  <Link to={`/reset-credentials/${record.instance}/${record.userId}`}>
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  const tab2columns = [
    {
      title: "Host Name",
      dataIndex: "hostName",
      key: "hostName",
    },
    {
      title: "OS",
      dataIndex: "os",
      key: "os",
    },
    {
      title: "Private IP",
      dataIndex: "privateIp",
      key: "privateIp",
    },
    {
      title: "PublicIp",
      dataIndex: "publicIp",
      key: "publicIp",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    //   {
    //     title: 'PAM Code',
    //     dataIndex: 'pamcode',
    //     key: 'pamcode',
    //     render: (data, record) => (
    //        "View"
    //       ),

    //   },
    {
      title: "Action",
      key: "Action",
      render: (_value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: "Auth Flow",
              },
              {
                key: "status",
                label: (
                  <Link onClick={() => handleStatus(record)}>
                    {record.state === "Active" ? "Deactivate" : "Activate"}
                  </Link>
                ),
                danger: record.state === "Active" ? true : false,
              },
              {
                key: "Password Policies",
                label: (
                  <Link to={`/endpoint/passwordPolicies?hostaddress=${record.hostName}`}>
                    Password Policies
                  </Link>
                ),
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  const fetchInstanceData = () => {
    let pageDetails = {
      domainId: 1,
      pageId: currentPage,
      pageSize: PAGESIZE,
      search: search,
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointData(res?.data?.instances);
        setTotalCount(res?.data?.total);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message === "Credentials are invalid") {
          logOutUser();
        } else {
          console.log("Error");
        }
      });
  };

  const handleOk = () => {
    setTimeout(() => {
      setisOpenPAM(false);
    }, 3000);
  };
  const handleCancel = () => {
    setisOpenPAM(false);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  href: "",
                  title: <HomeOutlined />,
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Endpoints Group</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Endpoint Group Details",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">{`Endpoint Group Details - ${groupName}`} </h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tab-container">
                <Tabs activeKey={selectedTab} onChange={() => handleTab()}>
                  <TabPane style={{ width: "65rem" }} tab="Endpoints" key="0">
                    <Table
                      rowKey="id"
                      dataSource={instaceDataById}
                      columns={tab2columns}
                      pagination={{ ...paginationParams, total: totalUsers }}
                      onChange={handleTableChange}
                      loading={isLoading}
                    />
                  </TabPane>
                  <TabPane style={{ width: "65rem" }} tab="Endpoints Users" key="1">
                    <Table
                      dataSource={epmUser}
                      columns={tab1columns}
                      loading={isLoading}
                      pagination={{ ...paginationParams, total: totalRecords }}
                      onChange={handleTableChange}
                    />
                  </TabPane>
                  <TabPane style={{ width: "65rem" }} tab="Auth Flow" key="2">
                    <Table columns={Authcolum} />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title={`PAM Code`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        width={900}
      >
        <Row>
          <Col span={24}></Col>
        </Row>
      </Modal>
    </>
  );
};

export default EndpointGroupDetails;
