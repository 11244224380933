import { Cascader, Col, Input, Modal, Row, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useDebounce } from "../../../common/debounce";

const ScreenRecording = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setfilterBy] = useState("RECORDINGMIMETYPE");
  const [filteredValue, setFilteredValue] = useState("guac");
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [sessionData, setSessionData] = useState([]);
  const [endpoint, setEndpoint] = useState([]);
  const [endpointUser, setEndpointUser] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [url, setUrl] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const epmUsername = queryParams.get("epmUser");
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchSessionRecording();
  }, [paginationParams, debouncedValue, filterBy, filteredValue]);

  useEffect(() => {
    fetchInstance();
    fetchEndpointUser();
  }, []);

  const columns = [
    {
      title: "Endpoint User",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Endpoint",
      dataIndex: "endpoint",
      key: "endpoint",
    },
    {
      title: "Session Length",
      dataIndex: "recordingLength",
      key: "recordingLength",
    },
    {
      title: "Session Time",
      dataIndex: "sessionRecordingTime",
      key: "sessionRecordingTime",
    },
    {
      title: "View Session Recording",
      dataIndex: "view",
      key: "view",
      disabled: true,
      render: (data, record) => (
        <Link
          disabled={record.recordingLength === "" || record.recordingLength === "\n" ? true : false}
          onClick={() => {
            if (record.recordingLength === "" || record.recordingLength === "\n") {
              setIsOpenView(false);
            } else {
              setIsOpenView(true);
              setUrl(record.recordingUrl);
            }
          }}
        >
          View
        </Link>
      ),
    },
  ];

  const fetchSessionRecording = (e) => {
    let requestData = {
      pageId: paginationParams?.currentPage,
      pageSize: paginationParams?.pageSize,
      search,
      filter: {
        filterBy: epmUsername ? "ENDPOINTUSERS" : filterBy ?? "RECORDINGMIMETYPE",
        value: epmUsername ?? filteredValue ?? "guac",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/SessionRecording/listSessionRecording`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.total);
        setSessionData(
          res?.data?.sessionRecordings.map((data) => {
            return {
              ...data,
              sessionRecordingTime:
                data.sessionRecordingTime &&
                moment(data.sessionRecordingTime)
                  .tz("America/Los_Angeles")
                  .format("MM-DD-YYYY  HH:mm z"),
            };
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch session recording",
          });
        }
      });
  };
  const fetchInstance = () => {
    let payload = {
      domainId: 1,
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoint(
          res?.data?.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchEndpointUser = async () => {
    let pageDetails = {
      domainId: 1,
      pageId: 1,
      pageSize: 10,
      token: AUTH_TOKEN(),
      filter: {
        filterBy: "",
        value: "",
      },
      instanceIds: [],
      search: "",
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpointUser(
          res?.data?.epmUsers?.map((user) => ({
            value: user.userId,
            label: user.userName,
          }))
        );
      })

      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints groups",
          });
        }
      });
  };
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
  };
  const option = [
    {
      value: "ENDPOINT",
      label: "Endpoints",
      children: endpoint,
    },
    {
      value: "ENDPOINTUSERS",
      label: "Endpoint User",
      children: endpointUser,
    },
  ];

  const handleCancel = () => {
    setIsOpenView(false);
  };

  const handleOk = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsOpenView(false);
    }, 3000);
    setIsLoading(false);
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={sessionData}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpenView && (
        <div className="video-container">
          <Modal
            title="Session Recordings"
            centered
            open={isOpenView}
            onOk={handleOk}
            onCancel={handleCancel}
            width={950}
            footer={null}
          >
            <iframe
              name="videoPlayer"
              width="900"
              height="530"
              src={url}
              title="Screen Recording"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default ScreenRecording;
