import { Col, Row, Select, Table, Button, Input, message, Card } from "antd";
import { AUTH_TOKEN, REACT_APP_API_TRANS_URL } from "../../constants";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
const { Option } = Select;
const Log = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const PAGESIZE = 10;
  const [filter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 0,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchLog();
  }, [filter, debouncedValue, paginationParams]);
  const fetchLog = () => {
    let currentOffset = 0;

    let pageDetails = {
      domainId: "1",
      filter: {
        transactionType: "",
        resourceIP: "",
        userIP: "",
        transactionStatus: "",
        date: "",
        hour: "",
      },
      limit: paginationParams?.pageSize,
      offset: paginationParams.currentPage
        ? (paginationParams.currentPage - 1) * paginationParams?.pageSize
        : currentOffset,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TRANS_URL}/GetTransactions`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data;
        if (res.data.transactions) {
          Data = res.data.transactions.map((data, i) => ({
            id: i,
            transactionId: data.ID,
            LdapUser: data.LdapUser,
            CID: data.CID,
            ChainAddress: data.ChainAddress,
            TransactionType: data.TransactionType,
            TransactionMessage: data.TransactionMessage,
            LocalUser: data.LocalUser,
            ResourceType: data.ResourceType,
            ResourceIP: data.ResourceIP,
            UserIP: data.UserIP,
            DateTime: data.DateTime,
            TransactionStatus: data.TransactionStatus,
            MerkleHash: data.MerkleHash,
          }));
        }
        setCredentialData(Data);
        setTotalCount(res?.data?.count);
        if (paginationParams.currentPage && paginationParams.currentPage > 0) {
          currentOffset = pageDetails.offset;
          paginationParams.pageSize = pageDetails.limit;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const columns = [
    { key: "LdapUser", title: "Directory User", dataIndex: "LdapUser" },
    { key: "LocalUser", title: "Endpoint User", dataIndex: "LocalUser" },
    { key: "MerkleHash", title: "Merkle Hash", dataIndex: "MerkleHash" },
    { key: "ResourceType", title: "Resource Type", dataIndex: "ResourceType" },
    {
      key: "TransactionType",
      title: "Transaction Type",
      dataIndex: "TransactionType",
    },
    {
      key: "TransactionStatus",
      title: "Transaction Status",
      dataIndex: "TransactionStatus",
    },
    { key: "CID", title: "CID", dataIndex: "CID" },
    // { key: "ChainAddress", title: "Chain Address", dataIndex: "ChainAddress" },
    // { key: "domainID", title: "Domain ID", dataIndex: "domainID" },
    { key: "DateTime", title: "Date & Hours", dataIndex: "DateTime" },
    // Add dataIndex for other columns if needed
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={credentialData}
            pagination={{
              ...paginationParams,
              total: totalCount,
              onChange: (page, pageSize) => {
                setPaginationParams({
                  currentPage: page,
                  pageSize: pageSize,
                });
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Log;
