import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, Table } from "antd";
import { useParams } from "react-router-dom";
import { fetchEPMUsers, fetchVerifiableCredentials } from "../../../common/functions";

export default function ViewCredentials() {
  const [endpointUsers, setEndpointUsers] = useState([]);
  const [endpointVCs, setEndpointVCs] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const { epmUserId } = useParams();

  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetchEPMUsers({}).then(({ epmUsers }) => {
      setEndpointUsers(epmUsers);
    });
  }, []);

  useEffect(() => {
    setPaginationParams((params) => ({ ...params, current: 1 }));
  }, [search]);

  useEffect(() => {
    setLoadingData(true);
    fetchVerifiableCredentials({
      epmUserId: Number(epmUserId),
      pageId: paginationParams.current,
      pageSize: paginationParams.pageSize,
      search,
    }).then(({ totalRecords, credentialData }) => {
      setTotalUsers(totalRecords);
      setEndpointVCs(credentialData);
      setLoadingData(false);
    });
  }, [paginationParams]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpointVCs([]);
    }
  };

  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">Endpoint User Details</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Row
                  style={{ marginBottom: "2rem", gap: "1.5rem" }}
                  className="search-box-container"
                >
                  <Col span={6}>
                    <p className="search-label">
                      <span>Endpoint User</span>
                    </p>
                    <div>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Endpoint User"
                        disabled={true}
                        value={Number(epmUserId)}
                        labelInValue={true}
                        options={endpointUsers.map((endpointUser) => ({
                          value: endpointUser.epmUserId,
                          label: endpointUser.user,
                        }))}
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <p className="search-label">Search</p>
                    <div>
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      rowKey="id"
                      pagination={{ ...paginationParams, total: totalUsers }}
                      onChange={handleTableChange}
                      loading={loadingData}
                      dataSource={endpointVCs}
                      columns={[
                        {
                          title: "Credential Name",
                          dataIndex: "userName",
                          key: "user",
                        },
                        {
                          title: "Schema",
                          key: "endpoint-name",
                          dataIndex: "schema",
                        },
                        {
                          title: "Created at",
                          dataIndex: "createDate",
                          key: "credentials",
                        },
                        {
                          title: "Issuer",
                          key: "endpoint-name",
                          dataIndex: "issuer",
                        },
                        {
                          title: "Status",
                          dataIndex: "status",
                          key: "credentials",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
