import { Col, Row, Select, Table, Button, Input, message } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";

const Jumpserver = () => {
  const [jumpServerData, setJumpServerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignConnection, setIsAssignConnection] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const PAGESIZE = 10;
  const [filter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = message.useMessage();

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    const fetchJumpserver = () => {
      let pageDetails = {
        pageId: currentPage,
        pageSize: PAGESIZE,
        search,
        filter: {
          filterBy: filter ? "status" : "",
          value: filter ?? "",
        },
        token: AUTH_TOKEN(),
      };
      setIsLoading(true);
      axios
        .post(`${REACT_APP_PAM_API}/jumpserver/listJumpServer`, pageDetails, {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setJumpServerData(res?.data?.jumpServers);
          setTotalCount(res?.data?.total);
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err?.response?.data?.message === "Credentials are invalid" ||
            err?.response?.status === 401
          ) {
            logOutUser();
          } else {
            notification.open({
              type: "error",
              content: "Unable to fetch jump server",
            });
          }
        });
    };
    fetchJumpserver();
  }, [filter, currentPage, debouncedValue]);

  const columns = [
    { title: "Server Name", dataIndex: "serverName", key: "serverName" },
    { title: "Jump Host Address", dataIndex: "publicIpAddress", key: "publicIpAddress" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) =>
        val.toLowerCase() === "active"
          ? "Active"
          : val.toLowerCase() === "in progress"
          ? "In progress"
          : "Inactive",
    },
    {
      title: "View Connections",
      dataIndex: "viewConnections",
      key: "viewConnection",
      render: (_, record) => <Link to={`/connections/${record.publicIpAddress}`}>Show</Link>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_value, record) => (
        <Link to={`/connections/createConnection?serverName=${record.serverName}`}>
          Assign Connection
        </Link>
      ),
    },
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };

  const filterOption = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
    {
      label: "In progress",
      value: "In progress",
    },
  ];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Jump Server</h2>
            </Col>
            <Col>
              <Link to={"/linuxMachines/addJumpServer"}>
                <Button type="primary">Add Jump Server</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      <div>
                        <Select
                          showSearch
                          allowClear
                          placeholder="Filter by status"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={onFilterChange}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          options={filterOption}
                        />
                      </div>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={jumpServerData}
                pagination={{
                  pageSize: PAGESIZE,
                  total: totalCount,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Jumpserver;
