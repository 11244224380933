import { Col, Row, Select, Table, Button, Input, message, Card } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_DID_URL } from "../constants";
import axios from "axios";
import { logOutUser } from "../common";
import ReactApexChart from "react-apexcharts";
const { Option } = Select;
const Dashboard = () => {
  const [credShared, setCredShared] = useState(null);
  const [notification, setNotification] = message.useMessage();
  useEffect(() => {
    fetchCredShared();
  }, []);

  const fetchCredShared = () => {
    let payload = {
      domainId: 1,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/countNewCredentialShared`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setCredShared(res?.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };

  const chartData = [
    { platform: "Linux", users: credShared && credShared?.LinuxCount },
    { platform: "Windows", users: credShared && credShared?.WindowsCount },
  ];

  const chartDataTransactions = [
    { platform: "Credentials Shared", users: 20000 },
    { platform: "Login request accepted", users: 10000 },
    { platform: "Login request Denied", users: 7000 },
    { platform: "DID Issued", users: 10000 },
  ];

  // Chart options
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    xaxis: {
      categories: chartData.map((item) => item.platform),
      title: {
        text: "Operating System",
      },
    },
    yaxis: {
      title: {
        text: "Number of Users",
      },
    },
  };
  const chartSeries = [{ name: "Users", data: chartData.map((item) => item.users) }];

  const chartOptionsTransaction = {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    xaxis: {
      categories: chartDataTransactions.map((item) => item.platform),
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
  };
  const chartSeriesTransactions = [
    { name: "Users", data: chartDataTransactions.map((item) => item.users) },
  ];
  const allowedItems = 75; // Replace with your actual data
  const deniedItems = 25; // Replace with your actual data

  // Calculate percentages
  const totalItems = allowedItems + deniedItems;
  const allowedPercentage = (allowedItems / totalItems) * 100;
  const deniedPercentage = (deniedItems / totalItems) * 100;

  // Define pie chart options
  const chartOptionsPie = {
    labels: ["Allowed", "Denied"],
    colors: ["#33FF33", "#FF3333"],
    chart: {
      height: 350, // Set the height to 350 pixels
      type: "pie",
    },
  };

  // Define pie chart series
  const chartSeriesPie = [allowedPercentage, deniedPercentage];

  return (
    <Row className="content-conatiner">
      {setNotification}

      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Dashboard</h2>
          </Col>
          <Col>
            {/* <Link to={"/linuxMachines/addJumpServer"}>
              <Button type="primary">Add Jump Server</Button>
            </Link> */}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="New Credential Shared" style={{ marginBottom: 20 }}>
              <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={320} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Transactions" style={{ marginBottom: 20 }}>
              <ReactApexChart
                options={chartOptionsTransaction}
                series={chartSeriesTransactions}
                type="bar"
                height={320}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Allowed Vs Denied Credentials" style={{ height: "440px" }}>
              <ReactApexChart
                style={{ marginTop: "2rem" }}
                options={chartOptionsPie}
                series={chartSeriesPie}
                type="pie"
                height={320}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="sub-title">Data Overview</h4>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: "1rem" }}>
          <Col span={8}>
            <Card>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                4200
              </h3>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  marginTop: "0.5rem",
                }}
              >
                Endpoints
              </h4>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                50000
              </h3>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  marginTop: "0.5rem",
                }}
              >
                Users
              </h4>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                5.1M
              </h3>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  marginTop: "0.5rem",
                }}
              >
                Access Requests
              </h4>
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                400
              </h3>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  marginTop: "0.5rem",
                }}
              >
                Groups
              </h4>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                4.2M
              </h3>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  marginTop: "0.5rem",
                }}
              >
                VCs
              </h4>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                10000
              </h3>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  marginTop: "0.5rem",
                }}
              >
                Tickets
              </h4>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
