import React, { useEffect, useState } from "react";
import { Col, Row, Select, Button, Input, Form, message, Space, Tooltip, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../../constants";
import { logOutUser } from "../../../common";

const AddPasswordPolicy = () => {
  const [serverHostName, setServerHostName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [notification, setNotification] = message.useMessage();
  const [template, setTemplate] = useState([{ value: 1, label: "Default" }]);
  const [formData, setFormData] = useState({});
  const [checkPolicyName, setCheckPolicyName] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchInstance();
    fetchCredRotationPolicy();
  }, []);

  useEffect(() => {
    const initialValues = {
      userType: "LDAP",
      rotationTime: 1,
      credentialType: "Web console only",
      template: "Default",
    };
    form.setFieldsValue(initialValues);
    setFormData({
      userType: "LDAP",
      rotationTime: "1 day",
      credentialType: "Web console only",
      template: "Default",
    });
  }, []);

  const fetchInstance = () => {
    let payload = {
      domainId: 1,
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setServerHostName(
          res.data.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIp,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };

  const fetchCredRotationPolicy = (e) => {
    let requestData = {
      pageId: 1,
      pageSize: 100,
      search: "",
      credentialType: "PASSWORD",
      filter: {
        filterBy: "",
        value: "",
      },
      domainId: 1,
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAllCredentialRotationPolicy`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let resData = res?.data?.credentialPolicies;
        const policy = resData?.map((data) => {
          return {
            value: data.policyId,
            label: data.policyName,
            ...data,
          };
        });

        setTemplate([...template, ...policy]);
        setCheckPolicyName(resData.map((data) => data.policyName));
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };

  const userType = [
    { value: 1, label: "LDAP" },
    { value: 2, label: "Local user" },
  ];
  const rotateEvery = [
    { value: "1", label: "1 day", text: 1 },
    { value: "2", label: "Every week", text: 7 },
    { value: "3", label: "30 days", text: 30 },
    { value: "4", label: "After each use", text: 0 },
  ];

  const handleUserType = (value, data) => {
    form.setFieldsValue({
      userType: data?.label,
    });
    setFormData({ ...formData, userType: value });
  };
  const handleTemplate = (value, data) => {
    console.log({ userType: data.userType, rotationTime: data.rotationTime });
    setFormData({ ...formData, template: value });
    form.setFieldsValue({
      instanceId: data.endpoints.map((i) => i.instanceId[0]),
      instanceGroupId: data?.endpointGroups?.map((i) => i.groupId) ?? [],
    });
  };

  const handleRotate = (value, data) => {
    form.setFieldsValue({
      rotationTime: data?.text,
    });
    setFormData({ ...formData, rotationTime: value });
  };
  const handleCredential = (value, data) => {
    form.setFieldsValue({
      credentialType: data.label,
    });
    setFormData({ ...formData, credentialType: value });
  };
  const handleEndpoint = (value) => {
    form.setFieldsValue({
      instanceId: value,
    });
  };
  const handleEndpointGrp = (value) => {
    form.setFieldsValue({
      instanceGroupId: value,
    });
  };

  const accessCredential = [
    { value: "1", label: "Web console only" },
    { value: "2", label: "Send to wallet" },
  ];

  const handleInstall = (e) => {
    let domainId = 1;

    let payload = {
      ...e,
      domainId,
      sessionExpiryTime: 15,
      credentialMedium: "",
      keyFormat: "",
      keyEncryption: "",
      publicKeyFolderPath: "",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/addCredentialRotationPolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        navigate(-1);
        notification.open({
          type: "success",
          content: "Password add successfully!",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to add credential rotation policy password",
        });
      });
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <Tooltip title="The Newest policy will be overridden">
                <h2 className="title">Credential Rotation Policy Password</h2>
              </Tooltip>
            </Col>
          </Row>
          <Form form={form} layout="vertical" onFinish={handleInstall}>
            <Row align="middle" justify="space-between">
              <Col span={24}>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col span={8}>
                    <p style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.88)" }}>
                      Copy from Template
                    </p>
                    <Select
                      value={formData?.template}
                      placeholder="Select user type"
                      onChange={handleTemplate}
                      tokenSeparators={[","]}
                      showSearch
                      optionFilterProp="label"
                      options={template}
                    />{" "}
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Checkbox>Set as default for all endpoints</Checkbox>
                  </Col>
                </Row>

                <Row align="middle" justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      name="policyName"
                      label="Policy Name"
                      rules={[
                        {
                          required: true,
                          message: "Select Policy Name",
                        },
                        {
                          validator(rule, value) {
                            return new Promise((resolve, reject) => {
                              if (checkPolicyName.includes(value)) {
                                reject("Policy name already exist");
                              } else {
                                resolve();
                              }
                            });
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="userType"
                      label="User Type"
                      rules={[
                        {
                          required: true,
                          message: "Select User Type",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select user type"
                        onChange={handleUserType}
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={userType}
                        value={formData?.userType}
                      />{" "}
                    </Form.Item>
                  </Col>

                  <Col span={4}></Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      name="rotationTime"
                      label="Rotate Every"
                      rules={[
                        {
                          required: true,
                          message: "Select Rotate Every",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Rotate every"
                        onChange={handleRotate}
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={rotateEvery}
                        value={formData?.rotationTime}
                      />{" "}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="credentialType"
                      label="Access Credential"
                      rules={[
                        {
                          required: true,
                          message: "Select Access Credential",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select access credential"
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={accessCredential}
                        onChange={handleCredential}
                        value={formData?.credentialType}
                      />{" "}
                    </Form.Item>
                  </Col>
                  <Col span={4}></Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      name="instanceId"
                      label="Endpoints"
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoints",
                        },
                      ]}
                    >
                      <Select
                        // mode="multiple"
                        placeholder="Select endpoints"
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={serverHostName}
                        onChange={handleEndpoint}
                      />{" "}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="instanceGroupId"
                      label="Endpoints Groups"
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoints Groups",
                        },
                      ]}
                    >
                      <Select
                        // mode="multiple"
                        placeholder="Select endpoints groups"
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={serverHostName}
                        onChange={handleEndpointGrp}
                      />{" "}
                    </Form.Item>
                  </Col>

                  <Col span={4}></Col>
                </Row>

                <Row justify="end">
                  <Col span={8}>
                    <Space>
                      <Button onClick={() => navigate(-1)}>Cancle</Button>
                      <Button type="primary" htmlType="submit" loading={isLoading}>
                        Save
                      </Button>
                    </Space>
                  </Col>
                  <Col span={1}></Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddPasswordPolicy;
