import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  notification,
  Row,
  Col,
  message,
} from "antd";
import {
  AUTH_TOKEN,
  REACT_APP_API,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
} from "../../../constants";

import axios from "axios";
import { logOutUser } from "../../../common";
import { fetchEndpoints } from "../../../common/functions";

const { TextArea } = Input;
const { Option } = Select;

const EditEpmGroupModal = ({ isEditgroup, setIsEditgroup, selectedRecords }) => {
  const [groupName, setGroupName] = useState("");
  const [baseDN, setBaseDN] = useState("");
  const [nameFormat, setNameFormat] = useState("");
  const [groupFormat, setGroupFormat] = useState("");
  const [metaData, setMetaData] = useState("");
  const [validGroupName, setValidGroupName] = useState(true);
  const [validMetaData, setValidMetaData] = useState(true);
  const [validBaseDN, setValidBaseDN] = useState(true);
  const [validInstance, setValidInstance] = useState(true);
  const [instanceData, setInstanceData] = useState([]);
  const [selectedInstancesRow, setSelectedInstancesRow] = useState([]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();

  useEffect(() => {
    fetchEndpoints({}).then((instances) => {
      setInstanceData(
        instances.map((instance) => ({
          id: instance.instanceId,
          text: instance.hostName,
          publicIp: instance.publicIp,
        }))
      );
    });
  }, []);

  const fetchEndpointGroup = async () => {
    let pageDetails = {
      domainId: "1",
      pageId: 1,
      pageSize: 10,
      token: AUTH_TOKEN(),
      filter: search,
      // filter: {
      //   filterBy: "groupName",
      //   value: searchText ? searchText : "",
      // },
    };

    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      let Records = res?.data?.totalCount;
      if (res.data.groups) {
        let endpointGroupData = res?.data?.groups?.map((grp, i) => ({
          index: i,
          id: grp?.groupId,
          groupName: grp?.groupName,
          count: grp?.usersCount,
          // credential: grp.CredentialExpiry,
        }));
      } else {
      }
    } catch (err) {
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    }
  };

  const handleSaveGroup = (e) => {
    let data = {
      domainId: 1,
      groupId: selectedRecords.groupId,
      groupName: selectedRecords.groupName,
      instanceIds: selectedInstances ?? [],
      ou: groupFormat ?? "",
      cn: nameFormat ?? "",
      dc: baseDN ?? "",
      token: AUTH_TOKEN(),
    };

    axios
      .put(`${REACT_APP_API_PAM_URL}/instanceGroup/editInstanceGroup`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: `Group Edited Successfull`,
        });
      })
      .catch((err) => {
        if (err.response.data.message == "Credentials are invalid") {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        }
      });
  };

  const handleOk = () => {
    let pageId = 1;

    handleSaveGroup();
    fetchEndpointGroup();

    setTimeout(() => {
      setIsEditgroup(false);
    }, 3000);
  };
  const handleCancel = () => {
    setIsEditgroup(false);
  };
  console.log(selectedInstances);

  return (
    <Modal
      open={isEditgroup}
      title={`Edit Group`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Save
        </Button>,
      ]}
      width={800}
    >
      {setNotification}
      <Row>
        <Col span={24}>
          <Row className="search-box-container">
            <Col span={24}>
              <div>
                <p className="search-label">Group Name</p>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Enter Group Name"
                  onChange={(e) => setGroupName(e.target.value)}
                  defaultValue={selectedRecords.groupName}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "2rem", gap: "1.5rem" }} className="search-box-container">
            <Col span={24}>
              <p className="search-label">Endpoints</p>
              <div>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  mode="multiple"
                  placeholder="Search Endpoints"
                  allowClear
                  onChange={(value) => setSelectedInstances(value)}
                  options={instanceData.map((instanceData) => ({
                    value: instanceData.id,
                    label: instanceData.text,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <p className="search-label">Other Metadata </p>
              <TextArea
                style={{ width: "100%" }}
                label="Other Metadata"
                validateStatus={validMetaData ? "" : "error"}
                help={validMetaData ? "" : "Invalid Other Metadata"}
                rows={8}
                value={metaData}
                onChange={(e) => {
                  setMetaData(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditEpmGroupModal;
