import {
  Col,
  Row,
  Select,
  Table,
  Dropdown,
  Button,
  Input,
  message,
  Space,
  Alert,
  Badge,
  Tooltip,
  Cascader,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { MoreOutlined } from "@ant-design/icons";
import AssignConnection from "./AssignUser";
import AssignUser from "./AssignUser";
import AuthFlow from "./AuthFlow";
import EndpointDetails from "./EndpointDetails";
import { useDebounce } from "../../../common/debounce";
import moment from "moment-timezone";

const Endpoints = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [endpointData, setEndpointData] = useState([]);
  // const [filter, setFilter] = useState(null);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [isAssignUser, setIsAssignUser] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [isLocalGroup, setisLocalGroup] = useState(false);
  const [selectedEndpoints, setSelectedEndpoints] = useState(null);
  const [isAuthFlow, setIsAuthFlow] = useState(false);
  const [isTabChange, setIsTabChange] = useState(false);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchInstance();
  }, [debouncedValue, filteredValue, paginationParams]);

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return (
        <Tooltip title={message}>
          <Badge status="error" text="Offline" />
        </Tooltip>
      );
    } else if (diff > 10) {
      return (
        <Tooltip title={message}>
          <Badge status="warning" text="Offline" />
        </Tooltip>
      );
    }
    return <Badge status="success" text="Online" />;
  };

  const handleStatus = async (record) => {
    let data = {
      domainId: 1,
      status: record.status === "Active" ? "Inactive" : "Active",
      instanceIds: [record.instanceId],
      token: AUTH_TOKEN(),
    };
    let url = `${REACT_APP_PAM_API}/instances/updateInstanceStatus`;
    try {
      await axios.put(url, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      await fetchInstance();
      notification.open({
        type: "success",
        content: `${record.hostName} is ${
          record.status === "Active" ? "deactivated" : "activated"
        } successfull`,
      });
    } catch (err) {
      if (
        err.response.data.message === "Credentials are invalid" ||
        err?.response?.status === 401
      ) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      }
    }
  };
  const columns = [
    {
      title: "Host Name",
      dataIndex: "hostName",
      key: "hostName",
      render: (data, record) => (
        <Link
          disabled={record.hostName === 0 ? true : false}
          to={`/endpoint/endpointDetails?hostAddress=${record.hostName}&defaultTab=hostname&instanceId=${record.instanceId}`}
        >
          {data}
        </Link>
      ),
    },
    { title: "OS", dataIndex: "osName", key: "osName" },
    {
      title: "User Count",
      dataIndex: "totalUsers",
      key: "totalUsers",
      render: (data, record) => (
        <Link
          disabled={record.totalUsers === 0 ? true : false}
          to={`/endpoint/endpointDetails?hostAddress=${record.hostName}&defaultTab=usercount&instanceId=${record.instanceId}`}
        >
          {data}
        </Link>
      ),
    },

    {
      title: "Private IP",
      dataIndex: "privateIp",
      key: "privateIp",
    },
    {
      title: "Public IP",
      dataIndex: "publicIp",
      key: "publicIp",
    },
    {
      title: "Group Count",
      dataIndex: "totalGroups",
      key: "totalGroups",
      render: (data, record) => (
        <Link
          onClick={setIsTabChange(true)}
          disabled={record.totalGroups === 0 ? true : false}
          to={`/endpoint/endpointDetails?hostAddress=${record.hostName}&tab=${isTabChange}&defaultTab=groupcount&instanceId=${record.instanceId}`}
        >
          {data}
        </Link>
      ),
    },

    {
      title: "State",
      dataIndex: "status",
      key: "status",
      render: (val) => (val.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
    {
      title: "Agent Status",
      dataIndex: "status",
      key: "status",
      render: (data, row) => EndpointStatus(row.lastActive),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedEndpoints(record);
                      setIsAssignUser(true);
                    }}
                  >
                    Assign Users
                  </Link>
                ),
              },
              {
                key: "authFlow",
                label: (
                  <Link
                    onClick={() => {
                      setIsAuthFlow(true);
                      setSelectedEndpoints(record);
                    }}
                  >
                    Auth Flow
                  </Link>
                ),
              },

              {
                key: "Password Policies",
                label: (
                  <Link to={`/endpoint/passwordPolicies?hostaddress=${record.hostName}`}>
                    Password Policies
                  </Link>
                ),
              },
              {
                key: "state",
                label: (
                  <Link onClick={() => handleStatus(record)}>
                    {record.status === "Active" ? "Deactivate" : "Activate"}
                  </Link>
                ),
                danger: record.status === "Active" ? true : false,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const fetchInstance = () => {
    let pageDetails = {
      domainId: 1,
      pageId: paginationParams?.currentPage,
      pageSize: paginationParams?.pageSize,
      search,
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      token: AUTH_TOKEN(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointData(
          res?.data?.instances?.map((data) => ({
            ...data,
          }))
        );
        setTotalCount(res?.data?.totalCount);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
  };

  const option = [
    {
      value: "State",
      label: "State",
      children: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
    },
    {
      value: "os",
      label: "OS",
      children: [
        {
          value: "windows",
          label: "Windows",
        },
        {
          value: "linux",
          label: "Linux",
        },
      ],
    },
  ];
  const filterOption = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Endpoints</h2>
            </Col>
            <Col>
              <Link to={"/endpoint/addEndpoint"}>
                <Button type="primary">Add Endpoints</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      {/* <div>
                        <Select
                          showSearch
                          allowClear
                          placeholder="Filter by status"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={(_, data) => {
                            setFilter(data ? data?.value : "");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          options={filterOption}
                        />
                      </div> */}
                      <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={endpointData}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isAssignUser && (
        <AssignUser
          isAssignUser={isAssignUser}
          setIsAssignUser={setIsAssignUser}
          selectedEndpoints={selectedEndpoints}
        />
      )}
      {isAuthFlow && (
        <AuthFlow
          isAuthFlow={isAuthFlow}
          setIsAuthFlow={setIsAuthFlow}
          selectedEndpoints={selectedEndpoints}
        />
      )}
    </>
  );
};

export default Endpoints;
