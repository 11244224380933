import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Table,
  Tag,
  Button,
  Input,
  notification,
  Cascader,
  Tabs,
  TabsProps,
  Dropdown,
  Breadcrumb,
} from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { MoreOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import EndpointDetailsUser from "./EndpointDetailsUser";
import EndpointDetailsGroup from "./EndpointDetailsGroup";
import EndpointDetailsAuthFlow from "./EndpointDetailsAuthFlow";
import EndpointDetailsServices from "./EndpointDetailsServices";

const EndpointDetails = ({ selectedEndpoints }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  const isInstanceId = queryParams.get("instanceId");
  const defaultTab = queryParams.get("defaultTab");
  const [selectedTab, setSelectedTab] = useState("1");
  const navigate = useNavigate();
  useEffect(() => {
    //handleTab();
    handleQueryParam();
  }, []);

  const items = [
    { key: "1", label: "Endpoint User", children: <EndpointDetailsUser /> },
    {
      key: "2",
      label: "Local User Group",
      children: <EndpointDetailsGroup />,
    },
    {
      key: "3",
      label: "Auth Flow",
      children: <EndpointDetailsAuthFlow />,
    },
    {
      key: "4",
      label: "Services",
      children: <EndpointDetailsServices />,
    },
  ];

  const handleTab = (key) => {
    console.log(key)
    setSelectedTab(key)
  };
  const handleQueryParam = (key) => {
    console.log(defaultTab);
    // if (!defaultTab) {
    //   setSelectedTab("1")
    //   return;
    // }
    if (defaultTab === "hostname") {
      setSelectedTab("1");
    } else if (defaultTab === "usercount") {
      setSelectedTab("1");
      console.log(defaultTab);
    } else if (defaultTab === "groupcount") {
      setSelectedTab("2");
      console.log(defaultTab);
    }
  };

  return (
    <>
      <Row style={{ marginBottom: "1rem" }}>
        <Breadcrumb
          items={[
            {
              href: "",
              title: <HomeOutlined />,
            },
            {
              title: (
                <>
                  <UserOutlined />
                  <Link onClick={() => navigate(-1)}>
                    <span>Endpoints</span>
                  </Link>
                </>
              ),
            },
            {
              title: "Endpoint Details",
            },
          ]}
        />
      </Row>
    {/* <h1> {selectedTab}</h1> */}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">{`Endpoint Details- ${hostAddress}`}</h2>
            </Col>
          </Row>
          <Row className="tab-container">
            <Col span={24}>
              <Tabs
              activeKey={selectedTab}
                onChange={handleTab}
                items={items}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointDetails;
