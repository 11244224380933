import { Cascader, Col, Input, Modal, Row, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL } from "../../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { useDebounce } from "../../../common/debounce";

const TextRecording = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [sessionData, setSessionData] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [url, setUrl] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchSessionRecording();
  }, [paginationParams, debouncedValue]);

  const columns = [
    {
      title: "Endpoint User",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Endpoint Name",
      dataIndex: "endpoint",
      key: "endpoint",
    },
    {
      title: "Session Time",
      dataIndex: "sessionRecordingTime",
      key: "sessionRecordingTime",
    },
    {
      title: "View Session Recording",
      dataIndex: "view",
      key: "view",
      render: (data, record) => (
        <Link
          onClick={() => {
            setIsOpenView(true);
            setUrl(record.recordingUrl);
          }}
        >
          View
        </Link>
      ),
    },
  ];

  const fetchSessionRecording = (e) => {
    let requestData = {
      pageId: paginationParams?.currentPage,
      pageSize: paginationParams?.pageSize,
      search,
      filter: {
        filterBy: "RECORDINGMIMETYPE",
        value: "typescript",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/SessionRecording/listSessionRecording`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.total);
        setSessionData(
          res?.data?.sessionRecordings.map((data) => {
            return {
              ...data,
              sessionRecordingTime:
                data.sessionRecordingTime &&
                moment(data.sessionRecordingTime)
                  .tz("America/Los_Angeles")
                  .format("MM-DD-YYYY  HH:mm z"),
            };
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch session recording",
          });
        }
      });
  };

  const handleCancel = () => {
    setIsOpenView(false);
  };

  const handleOk = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsOpenView(false);
    }, 3000);
    setIsLoading(false);
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={sessionData}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpenView && (
        <Modal
          title="Session Text"
          centered
          open={isOpenView}
          onOk={handleOk}
          onCancel={handleCancel}
          width={950}
          footer={null}
        >
          <object
            type="text/html"
            data={url}
            width="900px"
            height="550px"
            style={{ overflow: "auto", background: "#fff", color: "#000" }}
          />
        </Modal>
      )}
    </>
  );
};

export default TextRecording;
