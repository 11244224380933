import { Col, Row, Select, Table, Button, Input, message, Card } from "antd";
import { AUTH_TOKEN, REACT_APP_API_DID_URL } from "../../constants";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
const { Option } = Select;
const SshCredential = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchCredential();
  }, [filter, debouncedValue, paginationParams]);
  const fetchCredential = () => {
    let pageDetails = {
      domainId: 1,
      pageNumber: paginationParams?.currentPage,
      pageSize: paginationParams?.pageSize,
      filter: {
        filterType: "SSH",
        searchType: "",
        searchValue: "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/credential/credentialList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data;
        if (res.data.data) {
          Data = res.data.data.map((cred, i) => ({
            id: i,
            text: cred.credential_name,
            issuerName: cred.issuer_name,
            walletUser: cred.email_address,
            endpointUser: cred.epm_user_name,
            issuedAt:
              cred.created_at &&
              moment(cred.created_at).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z"),
            // credentialId: cred.credentialId,
            expireDate:
              cred.expirationdate &&
              moment(cred.expirationdate).tz("America/Los_Angeles").format("MM-DD-YYYY HH:mm z"),
            status: cred.status,
          }));
        }
        console.log(Data);
        setCredentialData(Data);
        setTotalCount(res?.data?.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const columns = [
    { key: "text", title: "VNC Credential Name", dataIndex: "text" },
    { key: "walletUser", title: "Wallet User", dataIndex: "walletUser" },
    { key: "issuedAt", title: "Created Date", dataIndex: "issuedAt" },
    { key: "expireDate", title: "Expiry Date", dataIndex: "expireDate" },
    { key: "issuerName", title: "Issuer", dataIndex: "issuerName" },
    { key: "status", title: "Status", dataIndex: "status" },
    { key: "endpointUser", title: "Endpoint User", dataIndex: "endpointUser" },

    //{ key: "action1", value: "Action" }
  ];
  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={credentialData}
            pagination={{
              ...paginationParams,
              total: totalCount,
              onChange: (page, pageSize) => {
                setPaginationParams({
                  currentPage: page,
                  pageSize: pageSize,
                });
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SshCredential;
