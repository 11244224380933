import { Col, Row, Tabs, Select, Table, Dropdown, Button, message, Input, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { logOutUser } from "../../../common";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  REACT_APP_API_DID_URL,
} from "../../../constants";
import axios from "axios";
import AssignCredentials from "./AssignCredentials";
import AddUser from "./AddUser";
import ImportUsers from "./ImportUsers";
import EditUsers from "./EditUsers";
import { useDebounce } from "../../../common/debounce";

function Users() {
  const [users, setUsers] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignCredentials, setIsAssignCredentials] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [search, setSearch] = useState("");
  const [isAddUser, setIsAddUser] = useState(false);
  const [isImportUser, setIsImportUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const debouncedValue = useDebounce(search, 500);

  const PAGESIZE = 10;
  useEffect(() => {
    setIsLoading(true);
    fetchAdUser();
  }, [debouncedValue, filter, currentPage]);
  const fetchAdUser = () => {
    let payload = {
      domainId: 1,
      pageId: currentPage,
      pageSize: PAGESIZE,
      search,
      filter: {
        filterBy: "",
        value: "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.totalUsers);
        setUsers(
          res?.data?.users?.map((user) => ({
            id: user.id,
            firstName: user.firstName,
            phone: user.phoneNumber,
            lastName: user.lastName,
            email: user.emailAddress,
            status: user.status,
            otpMethod: user.otpMethod,
            metaData: user.metadata,
            groups: user.groups,
            dc: "abc",
            cn: "asif ali",
            ou: "penang",
            ou1: "penang",
            domain: "Authnull-India",
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Credentials are invalid") {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch users",
          });
        }
      });
  };

  const handleRevokeWallet = (record) => {
    console.log(record);
    let payload = {
      emailId: record.email,
      token: AUTH_TOKEN(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/InvalidateWallet`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        setIsLoading(false);
        fetchAdUser();
        notification.open({
          type: "success",
          content: "Wallet Revoked Successful",
        });
      })
      .catch((err) => {
        console.log(err);
        notification.open({
          type: "error",
          content: "Failed to Revoke wallet",
        });
      });
  };

  const items = [
    {
      key: "1",
      label: (
        <Link
          onClick={() => {
            setIsAddUser(true);
          }}
        >
          Add User
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          onClick={() => {
            setIsImportUser(true);
          }}
        >
          Import Users
        </Link>
      ),
    },
  ];

  const handleStatus = async (record) => {
    let data = {
      id: record.id,
    };
    let url;
    if (record.status === "Active") {
      url = `${REACT_APP_PAM_API}/users/disableUser`;
    } else {
      url = `${REACT_APP_PAM_API}/users/enableUser`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      await fetchAdUser();
      notification.open({
        type: "success",
        content: `${record.email} is ${
          record.status === "Active" ? "deactivated" : "activated"
        } successfull`,
      });
    } catch (err) {
      if (
        err.response.data.message === "Credentials are invalid" ||
        err?.response?.status === 401
      ) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      }
    }
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Users</h2>
            </Col>
            <Col>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <Button type="primary">Add Users</Button>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row className="search-box-container">
                    <Col span={8}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Table
                        loading={isLoading}
                        columns={[
                          {
                            title: "Email",
                            dataIndex: "email",
                            key: "email",
                            render: (_value, record) => (
                              <Link to={`/directory/userDetails?userId=${record.id}`}>
                                {record.email}
                              </Link>
                            ),
                          },
                          {
                            title: "First Name",
                            dataIndex: "firstName",
                            key: "firstName",
                          },
                          {
                            title: "Last Name",
                            dataIndex: "lastName",
                            key: "lastName",
                          },
                          {
                            title: "Domain ID",
                            dataIndex: "domain",
                            key: "domain",
                          },
                          {
                            title: "Status",
                            dataIndex: "status",
                            key: "status",
                          },
                          {
                            title: "Action",
                            dataIndex: "action",
                            key: "action",
                            render: (_value, record) => (
                              <Dropdown
                                placement="bottomRight"
                                menu={{
                                  items: [
                                    {
                                      key: "assign",
                                      label: (
                                        <Link
                                          onClick={() => {
                                            setSelectedUserData(record);
                                            setIsAssignCredentials(true);
                                          }}
                                        >
                                          Assign Credentials
                                        </Link>
                                      ),
                                    },

                                    {
                                      key: "edit",
                                      label: (
                                        <Link
                                          onClick={() => {
                                            setIsEditUser(true);
                                            setSelectedUserData(record);
                                          }}
                                        >
                                          Edit
                                        </Link>
                                      ),
                                    },
                                    {
                                      key: "revokeWallet",
                                      label: (
                                        <Popconfirm
                                          title={`Sure to Revoke Wallet ${record.email}?`}
                                          onConfirm={() => handleRevokeWallet(record)}
                                        >
                                          <Link>Revoke Wallet</Link>
                                        </Popconfirm>
                                      ),

                                      danger: true,
                                    },
                                    {
                                      key: "status",
                                      label: (
                                        <Link onClick={() => handleStatus(record)}>
                                          {record.status === "Active" ? "Deactivate" : "Activate"}
                                        </Link>
                                      ),
                                      danger: record.status === "Active" ? true : false,
                                    },
                                  ],
                                }}
                              >
                                <Button type="text" shape="circle" icon={<MoreOutlined />} />
                              </Dropdown>
                            ),
                          },
                        ]}
                        rowKey="email"
                        dataSource={users}
                        pagination={{
                          pageSize: PAGESIZE,
                          total: totalCount,
                          onChange: (page) => {
                            setCurrentPage(page);
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {isAssignCredentials && (
        <AssignCredentials
          setIsAssignCredentials={setIsAssignCredentials}
          isAssignCredentials={isAssignCredentials}
          selectedUserData={selectedUserData}
          fetchDirectoryUsers={fetchAdUser}
          notification={notification}
        />
      )}
      {isAddUser && (
        <AddUser
          isAddUser={isAddUser}
          setIsAddUser={setIsAddUser}
          notification={notification}
          fetchUsers={fetchAdUser}
        />
      )}
      {isImportUser && (
        <ImportUsers
          isImportUser={isImportUser}
          setIsImportUser={setIsImportUser}
          notification={notification}
          fetchUsers={fetchAdUser}
        />
      )}
      {isEditUser && (
        <EditUsers
          isEditUser={isEditUser}
          setIsEditUser={setIsEditUser}
          selectedUserData={selectedUserData}
          fetchUsers={fetchAdUser}
          notification={notification}
        />
      )}
    </>
  );
}

export default Users;
