import { Cascader, Col, Input, Row, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { useDebounce } from "../../../common/debounce";

const ListPasswordPolicy = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [policyData, setPolicyData] = useState([]);
  const [endpoint, setEndpoint] = useState([]);
  const [endpointGroup, setEndpointGroup] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchCredRotationPolicy();
  }, [paginationParams, debouncedValue, filterBy, filteredValue]);

  useEffect(() => {
    fetchInstance();
    fetchEndpointGroup();
  }, []);

  const columns = [
    { title: "Policy Name", dataIndex: "policyName", key: "policyName", fixed: "left" },
    { title: "Rotation Time Frame", dataIndex: "rotationInDays", key: "rotationInDays" },
    { title: "User Type", dataIndex: "userType", key: "userType" },
    {
      title: "Endpoints Impacted",
      dataIndex: "endpoints",
      key: "endpoints",
      render: (tags) => (
        <span>
          {tags.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    { title: "Endpoint Groups", dataIndex: "endpointGroups", key: "endpointGroups" },
    { title: "Last Modified", dataIndex: "lastModified", key: "lastModified" },
    { title: "Status", dataIndex: "status", key: "status", fixed: "right" },
  ];

  const fetchCredRotationPolicy = (e) => {
    let requestData = {
      pageId: paginationParams?.currentPage,
      pageSize: paginationParams?.pageSize,
      search,
      credentialType: "PASSWORD",
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      domainId: 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAllCredentialRotationPolicy`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.totalCount);
        setPolicyData(
          res?.data?.credentialPolicies.map((data) => {
            return {
              ...data,
              lastModified:
                data.rotationTime &&
                moment(data.rotationTime).tz("America/Los_Angeles").format("MM-DD-YYYY  HH:mm z"),
            };
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };
  const fetchInstance = () => {
    let payload = {
      domainId: 1,
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoint(
          res?.data?.instances.map((ins) => ({
            value: ins.instanceId,
            label: ins.hostName,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchEndpointGroup = async () => {
    let pageDetails = {
      domainId: "1",
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      filter: "",
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/instanceGroup/listEndpointGroup`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpointGroup(
          res?.data?.groups?.map((grp) => ({
            value: grp.groupId,
            label: grp.groupName,
          }))
        );
      })

      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints groups",
          });
        }
      });
  };
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
  };
  const option = [
    {
      value: "ENDPOINT",
      label: "Endpoints",
      children: endpoint,
    },
    {
      value: "endpointGroup",
      label: "Endpoint Group",
      children: endpointGroup,
    },
    {
      value: "Status",
      label: "Status",
      children: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    },
  ];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={policyData}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ListPasswordPolicy;
