import { Col, Row, Select, Table, Button, Input, message, Card } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_DID_URL } from "../../constants";
import axios from "axios";
import { logOutUser } from "../../common";
import ReactApexChart from "react-apexcharts";
const { Option } = Select;
const DidDashboard = () => {
  const [transactionData, setTransactionData] = useState(
    Array.from({ length: 10 }).map((_, i) => ({
      id: i,
      walletUser: i % 2 ? "Asif" : i % 4 ? "Hussain" : i % 6 ? "Inam" : "Manju",
      credentialName:
        i % 2 ? "UbuntuVM" : i % 4 ? "Google Workspace" : "CloudFlare",
      credentialType: "Active Directory User ",
      transactionType: "Login",
      comments: "Successfully logged in",
    }))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignConnection, setIsAssignConnection] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const PAGESIZE = 10;
  const [filter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const columns = [
    { title: "Wallet User", dataIndex: "walletUser", key: "walletUser" },
    {
      title: "Credential Name",
      dataIndex: "credentialName",
      key: "credentialName",
    },
    {
      title: "Credential Type",
      dataIndex: "credentialType",
      key: "credentailType",
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [
    { key: "Credential Type", value: "credentialType" },
    { key: "Transaction Type", value: "transactioType" },
  ];
  useEffect(() => {
    const fetchJumpserver = () => {
      let pageDetails = {
        domainId: 1,
        pageId: currentPage,
        pageSize: PAGESIZE,
        search,
        // filter: {
        //   filterBy: filter ? "status" : "",
        //   value: filter ?? "",
        // },
        filter: "Issuer",
      };
      setIsLoading(true);
      axios
        .post(`${REACT_APP_API_DID_URL}/did/DIDList`, pageDetails, {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        })
        .then((res) => {
          setIsLoading(false);
          //   setTransactionData(res?.data?.jumpServers);
          setTotalCount(res?.data?.total);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.data?.message === "Credentials are invalid" ||
        err?.response?.status === 401) {
            logOutUser();
          } else {
            notification.open({
              type: "error",
              content: "Unable to fetch jump server",
            });
          }
        });
    };
    fetchJumpserver();
  }, [filter, search, currentPage]);
  const chartData = [
    { platform: "iOS Mobile", users: 2500 },
    { platform: "Android Mobile", users: 3000 },
    { platform: "Windows Desktop", users: 5000 },
    { platform: "macOS Desktop", users: 4000 },
    // Add more data for other platforms
  ];

  const chartDataTransactions = [
    { platform: "Credentials Requested", users: 20000 },
    { platform: "Credentials Sent", users: 10000 },
    { platform: "Credentials Denied", users: 7000 },
    { platform: "DID Issued", users: 10000 },
    // Add more data for other platforms
  ];

  // Chart options
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    xaxis: {
      categories: chartData.map((item) => item.platform),
      title: {
        text: "Platform",
      },
    },
    yaxis: {
      title: {
        text: "Number of Users",
      },
    },
  };
  const chartSeries = [
    { name: "Users", data: chartData.map((item) => item.users) },
  ];

  const chartOptionsTransaction = {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    xaxis: {
      categories: chartDataTransactions.map((item) => item.platform),
      title: {
        text: "Platform",
      },
    },
    yaxis: {
      title: {
        text: "Number of Users",
      },
    },
  };
  const chartSeriesTransactions = [
    { name: "Users", data: chartDataTransactions.map((item) => item.users) },
  ];
  const allowedItems = 75; // Replace with your actual data
  const deniedItems = 25; // Replace with your actual data

  // Calculate percentages
  const totalItems = allowedItems + deniedItems;
  const allowedPercentage = (allowedItems / totalItems) * 100;
  const deniedPercentage = (deniedItems / totalItems) * 100;

  // Define pie chart options
  const chartOptionsPie = {
    labels: ["Allowed", "Denied"],
    colors: ["#33FF33", "#FF3333"],
    chart: {
      height: 350, // Set the height to 350 pixels
      type: "pie",
    },
  };

  // Define pie chart series
  const chartSeriesPie = [allowedPercentage, deniedPercentage];

  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Dashboard</h2>
          </Col>
          <Col>
            {/* <Link to={"/linuxMachines/addJumpServer"}>
              <Button type="primary">Add Jump Server</Button>
            </Link> */}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="New Credential Requests" style={{ marginBottom: 20 }}>
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={320}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Transactions" style={{ marginBottom: 20 }}>
              <ReactApexChart
                options={chartOptionsTransaction}
                series={chartSeriesTransactions}
                type="bar"
                height={320}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Allowed Vs Denied Credentials"
              style={{ height: "440px" }}
            >
              <ReactApexChart
                style={{ marginTop: "2rem" }}
                options={chartOptionsPie}
                series={chartSeriesPie}
                type="pie"
                height={320}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
          <Row>
          <Col>
            <h4 className="sub-title">DID Transactions</h4>
          </Col>
        </Row>
            <Row>
              <Col span={24}>
                <Row
                  style={{ marginBottom: "2rem", gap: "2rem" }}
                  className="search-box-container"
                >
                  <Col span={6}>
                    <p className="search-label">Filter by</p>
                    <div>
                      <Select
                        showSearch
                        allowClear
                        placeholder="Filter by status"
                        labelInValue={true}
                        optionFilterProp="children"
                        onChange={onFilterChange}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {filterOption.map((option) => (
                          <Option
                            key={option.value}
                            value={option.value}
                            label={option.key}
                          >
                            {option.key}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Search</p>
                    <div>
                      <Input
                        placeholder="Search"
                        allowClear
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
       
        <Row>
          <Col span={24}>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={transactionData}
              pagination={{
                pageSize: PAGESIZE,
                total: totalCount,
                onChange: (page) => {
                  setCurrentPage(page);
                },
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DidDashboard;
