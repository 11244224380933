import axios from "axios";
import { AUTH_TOKEN, REACT_APP_API_OKTA } from "../constants";

export const logOutUser = () => {
  localStorage.setItem("signedIn", "false");
  axios
    .get(`${REACT_APP_API_OKTA}/okta/Logout`, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    })
    .then(() => {
      localStorage.clear();
      window.location.replace(`${REACT_APP_API_OKTA}/okta/RedirectLink`);
    })
    .catch((err) => {
      console.log(err);
    });
};
