import { Cascader, Col, Input, Row, Table, Tag, message,Button,Dropdown, } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { MoreOutlined, HomeOutlined, UserOutlined, } from "@ant-design/icons";
import Search from "antd/es/input/Search";

const EndpointDetailsAuthFlow = () => {
    const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [endpoints, setEndpoints] = useState([]);
  const [epmUser, setEpmUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState('0');
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalGroup, setTotalGroup] = useState(0);
  
  
  const navigate = useNavigate();
  
  const PAGESIZE = 10;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  const isInstanceId = queryParams.get("instanceId");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [policyData, setPolicyData] = useState([]);
  const [endpoint, setEndpoint] = useState([]);
  const [endpointGroup, setEndpointGroup] = useState([]);
  useEffect(() => {
    
  }, [paginationParams, search, filterBy, filteredValue]);

  useEffect(() => {

  }, [search, currentPage, paginationParams]);


  const tab3columns = [
    {
      title: "First Factor",
      dataIndex: "firstFactor",
      key: "firstFactor",
    },
    {
      title: "Second Factor",
      dataIndex: "secondFactor",
      key: "secondFactor",
    },
    {
      title: "Third Factor",
      dataIndex: "thirdFactor",
      key: "thirdFactor",
    },
    {
      title: "Default",
      dataIndex: "default",
      key: "defalut",
    },
    {
      title: "PAM code",
      dataIndex: "pamCode",
      key: "pamCode",
      },
    
  ];

  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
  };
  const option = [
    {
      value: "ENDPOINT",
      label: "Endpoints",
      children: endpoint,
    },
    {
      value: "endpointGroup",
      label: "Endpoint Group",
      children: endpointGroup,
    },
    {
      value: "Status",
      label: "Status",
      children: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    },
  ];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
        
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={tab3columns}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointDetailsAuthFlow;
