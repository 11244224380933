import { Button, Modal, Row, Col, Input, Form, Select } from "antd";
import React, { useState, useEffect } from "react";
import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
} from "../../../constants";
import { HEADERS } from "../../../common/functions";
import axios from "axios";
import { logOutUser } from "../../../common";

const EditGroups = ({ isEditGrp, setIsEditGrp, notification, fetchGrp, selectedGrp }) => {
  const [issuerData, setIssuerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  useEffect(() => {
    console.log(selectedGrp);
    fetchUser();
    setFormData([
      { name: ["groupName"], value: selectedGrp?.groupName },
      { name: ["cn"], value: selectedGrp?.cn },
      {
        name: ["ou"],
        value: selectedGrp?.ou,
      },
      {
        name: ["baseDn"],
        value: selectedGrp?.baseDn,
      },
    ]);
  }, []);
  const handleOk = (e) => {
    console.log(e);
    let payload = {
      ...e,
      groupId: selectedGrp.id,
      domainId: 1,
      applications: [],
      otpMethod: "1",
    };
    setIsLoading(true);
    axios
      .put(`${REACT_APP_API_PAM_URL}/groups/updateGroup`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        notification?.open({
          type: "success",
          content: "Group Updated Successful!",
        });
        setIsEditGrp(false);
        fetchGrp();
      })
      .catch((err) => {
        setIsLoading(false);
        let errMgs = err.response.data.message;
        notification.open({
          type: "error",
          content:
            errMgs === "User already exists"
              ? "User already exists"
              : errMgs === "Invalid credentials"
              ? "Credentials are invalid"
              : "Unable to update group",
        });
        if (errMgs === "Invalid credentials") {
          logOutUser();
        }
      });
  };
  const handleCancel = () => {
    setIsEditGrp(false);
  };

  const fetchUser = () => {
    let payload = {
      domainId: 1,
      pageId: 1,
      pageSize: 10,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, HEADERS)

      .then((res) => {
        setIssuerData(
          res?.data?.users?.map((data) => ({
            value: data.id,
            label: data.emailAddress,
          }))
        );
      });
  };

  const handleRole = (val) => {
    form.setFieldsValue({
      roles: val === "1" ? "3" : "1",
    });
  };
  const handleUser = (value) => {
    form.setFieldsValue({
      users: value,
    });
  };

  return (
    <Modal
      open={isEditGrp}
      title={`Edit Group - ${selectedGrp?.groupName}`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={900}
    >
      <div style={{ marginTop: "2rem" }}>
        <Row>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleOk}
              fields={formData}
              onFieldsChange={(_, allFields) => {
                setFormData(allFields);
              }}
            >
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="groupName"
                        label="Group Name"
                        rules={[
                          {
                            required: true,
                            message: "Group name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter group name" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="roles"
                        label="Role"
                        rules={[
                          {
                            required: true,
                            message: "Select role",
                          },
                        ]}
                        initialValue="1"
                      >
                        <Select
                          onChange={handleRole}
                          placeholder="Select role"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                        >
                          <Option value="1">Enduser </Option>
                          <Option value="2">Admin</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="users"
                        label="Users"
                        rules={[
                          {
                            required: true,
                            message: "Select User",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          onChange={handleUser}
                          placeholder="Select User"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={issuerData}
                        />{" "}
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="cn"
                        label="Common Name"
                        rules={[
                          {
                            required: true,
                            message: "Common Name is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:cn" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="baseDn"
                        label="Distinguish Name"
                        rules={[
                          {
                            required: true,
                            message: "Distinguish Name is required",
                          },
                          {
                            pattern: /^(dc=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+)$/,
                            message: "Distinguish Name must be dc=example,dc=com format",
                          },
                        ]}
                      >
                        <Input placeholder="eg:dc=example,dc=com" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="ou"
                        label="Group Format"
                        rules={[
                          {
                            required: true,
                            message: "Group Format is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:ou" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="metadata" label="Other Metadata">
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "end" }}>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EditGroups;
