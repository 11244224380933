import { Col, Row, Select, Table, Button, Input, message, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL } from "../../../constants";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { logOutUser } from "../../../common";
import AddGroups from "./AddGroups";
import EditGroups from "./EditGroups";
import { useDebounce } from "../../../common/debounce";
const { Option } = Select;
const Groups = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignConnection, setIsAssignConnection] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const PAGESIZE = 10;
  const [filter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [isAddGrp, setIsAddGrp] = useState(false);
  const [isEditGrp, setIsEditGrp] = useState(false);
  const [selectedGrp, setSelectedGrp] = useState(null);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchGrp();
  }, [filter, debouncedValue, paginationParams]);
  const fetchGrp = () => {
    let pageDetails = {
      domainId: 1,
      pageId: paginationParams?.currentPage,
      pageSize: paginationParams?.pageSize,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: search,
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            text: grp.groupName,
            people: grp.users.length,
            application: grp.applications.length,
            selectedApps: grp.applications ? grp.applications.map((app) => app.id) : [],
            selectedUsers: grp.users ? grp.users.map((user) => user.id) : [],
            ...grp,
          }));
        }
        setCredentialData(Data);
        setTotalCount(res?.data?.totalGroups);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch Groups",
          });
        }
      });
  };
  const columns = [
    { key: "text", title: "Group Name", dataIndex: "text" },
    { key: "people", title: "Users", dataIndex: "people" },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (_value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "edit",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedGrp(record);
                      setIsEditGrp(true);
                    }}
                  >
                    Edit
                  </Link>
                ),
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },

    // Add dataIndex for other columns if needed
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Groups</h2>
            </Col>
            <Col>
              <Button type="primary" onClick={() => setIsAddGrp(true)}>
                Add Groups
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={credentialData}
            pagination={{
              ...paginationParams,
              total: totalCount,
              onChange: (page, pageSize) => {
                setPaginationParams({
                  currentPage: page,
                  pageSize: pageSize,
                });
              },
            }}
          />
        </Col>
      </Row>
      {isAddGrp && (
        <AddGroups
          isAddGrp={isAddGrp}
          setIsAddGrp={setIsAddGrp}
          notification={notification}
          fetchGrp={fetchGrp}
        />
      )}
      {isEditGrp && (
        <EditGroups
          isEditGrp={isEditGrp}
          setIsEditGrp={setIsEditGrp}
          notification={notification}
          fetchGrp={fetchGrp}
          selectedGrp={selectedGrp}
        />
      )}
    </>
  );
};

export default Groups;
