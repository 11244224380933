import { Button, Col, DatePicker, Form, Input, Row, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  fetchEndpoints,
  fetchEPMUsers,
  fetchIssuerList,
  fetchWallets,
} from "../../../common/functions";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../../constants";
import { logOutUser } from "../../../common";

export default function CheckoutUser() {
  const [selectedPort, setSelectedPort] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [selectedIssuer, setSelectedIssuer] = useState("");
  const [selectedTtl, setSelectedTtl] = useState("30 Days");
  const [selectedProtocol, setSelectedProtocol] = useState();
  const [endpoints, setEndpoints] = useState([]);
  const [issuers, setIssuers] = useState([]);
  const [endpointUsers, setEndpointUsers] = useState([]);
  const [walletUsers, setWalletUsers] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [vcExpiry, setVcExpiry] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [jumpServerData, setJumpServerData] = useState([]);

  const location = useLocation();

  const { epmUserId } = useParams();

  const { instanceName, epmUsername, instanceId, os } = location.state;

  const [formData, setFormData] = useState({});

  const protocolPortMap = {
    SSH: 22,
    RDP: 3369,
    VNC: 5900,
    Telnet: 22,
  };
  useEffect(() => {
    let salVal = os === "windows" ? "RDP" : "SSH";
    setSelectedProtocol(salVal);
  }, []);
  useEffect(() => {
    // fetchEPMUsers({}).then(({ epmUsers }) => {
    //   setEndpointUsers(epmUsers);
    // });

    fetchWallets().then((wallets) => {
      setWalletUsers(wallets || []);
    });

    fetchIssuerList().then((issuerList) => {
      setIssuers(issuerList || []);
    });

    fetchJumpserver();

    const initialValues = {
      selectTTL: "30 Days",
      selectprotocol: os === "windows" ? "RDP" : "SSH",
      selectport: os === "windows" ? "3369" : "22",
    };
    setSelectedPort(22);
    form.setFieldsValue(initialValues);
    setFormData({
      selectTTL: "30 Days",
      selectprotocol: os === "windows" ? "RDP" : "SSH",
      selectport: os === "windows" ? "3369" : "22",
    });
  }, []);
  const fetchJumpserver = () => {
    let pageDetails = {
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/jumpserver/listJumpServer`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setJumpServerData(
          res?.data?.jumpServers.map((data) => ({
            value: data.id,
            label: data.serverName,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch jump server",
          });
        }
      });
  };
  const handleUserCheckout = async (e) => {
    let insId = [];
    insId.push(Number(instanceId));
    let data = {
      vcExpiry,
      domainId: 1,
      protocol: selectedProtocol,
      port: selectedPort,
      epmUserId: Number(epmUserId),
      instanceId: insId,
      privilegedUser: true,
      epmCredentialExpiry: 1,
      accessCredential: false,
      assignmentTimeLimit: selectedTtl,
      shareConnection: true,
      issueVerifiedCredential: true,
      walletEmail: selectedWallet,
      issuerId: Number(selectedIssuer),
      jumpServerId: e?.jumpserverId,
      token: AUTH_TOKEN(),
    };
    setLoading(true);

    axios
      .post(`${REACT_APP_API_PAM_URL}/checkouts/assignedUserCheckout`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        notification.open({
          type: "success",
          content: "Checkout jobs created successfully",
        });
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.message === "Credentials are invalid") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to checkout now, PLease try again",
          });
        }
      });
  };

  return (
    <>
      {setNotification}
      <Spin spinning={loading}>
        <Row className="content-conatiner">
          <Col span={24}>
            <Row>
              <Col>
                <h2 className="title">Checkout User</h2>
              </Col>
            </Row>

            <Form form={form} layout="vertical" onFinish={handleUserCheckout}>
              <div>
                <Row justify="space-between">
                  <Col span={6}>
                    <Form.Item
                      initialValue={epmUsername}
                      name="endpoint-users"
                      label="Endpoint User"
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoint User!",
                        },
                      ]}
                    >
                      <Input style={{ color: "#616161", backgroundColor: "#f5f5f5" }} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="endpoints"
                      label="Endpoint"
                      initialValue={instanceName}
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoint User!",
                        },
                      ]}
                    >
                      <Input
                        style={{ color: "#616161", backgroundColor: "#f5f5f5" }}
                        disabled={true}
                        defaultValue={instanceName}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      name="selectTTL"
                      label="Assignment Time Limit"
                      rules={[
                        {
                          required: true,
                          message: "Select TTL",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        value={formData.selectTTL}
                        options={[
                          { value: 0, label: "No Limit" },
                          { value: 1, label: "1 Day" },
                          { value: 30, label: "30 Days" },
                          { value: "custom", label: "Enter Custom Value" },
                        ]}
                        labelInValue={true}
                        onChange={(option) => setSelectedTtl(option.label)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <Row
                style={{
                  backgroundColor: "rgb(223 223 224 / 40%)",
                  padding: "1rem 0.5rem",
                  marginTop: "2rem",
                }}
              >
                <Col span={24}>
                  <Row>
                    <span
                      style={{ margin: "0rem 0rem 1rem 10px", fontWeight: "500" }}
                      className="para-title"
                    >
                      Share connection information
                    </span>
                  </Row>
                  <Row align="middle" justify="space-between">
                    <Col span={6}>
                      <Form.Item
                        name="selectprotocol"
                        label="Protocol"
                        rules={[
                          {
                            required: true,
                            message: "Select Protocol",
                          },
                        ]}
                        initialValue={formData.selectprotocol}
                      >
                        <Select
                          showSearch
                          labelInValue={true}
                          placeholder="Select Protocol"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          onChange={(option) => {
                            setSelectedProtocol(option.label);
                            setSelectedPort(protocolPortMap[option.value]);
                            form.setFieldValue("selectport", protocolPortMap[option.value]);
                          }}
                          options={[
                            { value: "SSH", label: "SSH", port: 22 },
                            { value: "RDP", label: "RDP", port: 3369 },
                            { value: "VNC", label: "VNC", port: 5900 },
                            { value: "Telnet", label: "Telnet", port: 22 },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name="selectport"
                        label="Port"
                        rules={[
                          {
                            required: true,
                            message: "Select Port",
                          },
                        ]}
                      >
                        <Input
                          disabled={true}
                          style={{ color: "#616161", backgroundColor: "DAD8D8" }}
                          value={selectedPort}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      {selectedTtl === "Enter Custom Value" && (
                        <Form.Item
                          name="select-custom-date"
                          label="Custom Date"
                          rules={[
                            {
                              required: true,
                              message: "Select Expiry",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100% " }}
                            onChange={(_date, dateString) => setVcExpiry(dateString)}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                style={{
                  backgroundColor: "rgb(223 223 223 / 35%)",
                  padding: "1rem 0.5rem",
                  marginTop: "2rem",
                }}
              >
                <Col span={24}>
                  <Row>
                    <span
                      style={{ margin: "0rem 0rem 1rem 10px", fontWeight: "500" }}
                      className="para-title"
                    >
                      Assign To ?
                    </span>
                  </Row>
                  <Row align="middle" justify="space-between">
                    <Col span={6}>
                      <Form.Item
                        name="userwallet"
                        label="User Wallet"
                        rules={[
                          {
                            required: true,
                            message: "Select User Wallet",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Wallet User"
                          showSearch
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={(option) => setSelectedWallet(option.label)}
                          options={walletUsers.map((walletUser) => ({
                            value: walletUser.email,
                            label: walletUser.email,
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="select-issuer"
                        label="Select Issuer"
                        rules={[
                          {
                            required: true,
                            message: "Select Issuer",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select Issuer"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={(option) => setSelectedIssuer(option.value)}
                          options={issuers.map((issuer, i) => ({
                            value: issuer.id,
                            label: issuer.text,
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="jumpserverId"
                        label="Select Jumpserver"
                        rules={[
                          {
                            required: true,
                            message: "Select jumpserver",
                          },
                        ]}
                      >
                        <Select
                          placeholder="select jumpserver"
                          onChange={(value) => {
                            form.setFieldsValue({
                              jumpserverId: value,
                            });
                          }}
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={jumpServerData}
                        />
                      </Form.Item>
                    </Col>{" "}
                  </Row>
                </Col>
              </Row>

              <Row justify="end" style={{ marginTop: "3rem" }}>
                <Col span={2}>
                  <Button type="default" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </Col>
                <Col span={2}>
                  <Button htmlType="submit" type="primary">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </>
  );
}
