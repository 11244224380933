import { Cascader, Col, Input, Row, Table, Tag, message,Button,Form,Select,Space } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, REACT_APP_PAM_API } from "../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { Link,useNavigate ,useLocation} from "react-router-dom";



const UserDetailsProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [userData, setuserData] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [editBtn, seteditBtn] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("userId");

  

  useEffect(() => {
    fetchUserDetails()
  }, [paginationParams, search, filterBy, filteredValue,console.log(userData)]);

  

  const fetchUserDetails = async () => {
    let pageDetails = {
      id: UserId,
      DomainId: 1,
    };
    try {
      let response = await axios.post(`${REACT_APP_PAM_API}/users/${UserId}`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });
     
     

     let userdata = response.data
      setuserData(userdata)

    } catch (err) {
     
      if (err.response.data.message == "Credentials are invalid") {
        logOutUser();
      } else {
        
      }
    }
  };

  

  return (
    <>
      {setNotification}
      <Row justify={"space-between"} className="content-conatiner">
        <Col span={24}>
         <Row>
            <Col span={16}>
              <Row justify={"space-between"}>
                <Col  span={8}><h2 className="sub-title" style={{marginTop:"1rem"}}>Attributes </h2></Col>
                <Col span={8}><Link><Button style={{marginTop:"0.6rem",fontSize:"17px"}} type="link"
                 onClick={()=>seteditBtn(!editBtn)}>
                    {editBtn? "Cancel":"Edit"}</Button></Link></Col>
              </Row>
              <Row>
                <Col span={24}>
              <Form 
                    form={form}
                    layout="vertical"
                    
              >
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    initialValue={userData.firstName}
                    name="firstname"
                    label="First Name"
                    
                   
                  >
                    <Input
                    placeholder={userData.firstName}
                      disabled={!editBtn}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                <Form.Item
                    name="lastname"
                    label="Last Name"
                    initialValue={userData.lastName}
                  >
                    <Input
                      placeholder={userData.lastName}
                      disabled={!editBtn}
                      
                    />
                  </Form.Item>
                </Col>
                
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                <Form.Item
                    name="phone"
                    label="User Phone"
                    initialValue={userData.phoneNumber}
                   
                  >
                    <Input
                      placeholder={userData.phoneNumber}
                      disabled={!editBtn}
                      
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                <Form.Item
                    name="email"
                    label="Email ID"
                    initialValue={userData.emailAddress}
                   
                  >
                    <Input
                      placeholder={userData.emailAddress}
                      disabled={!editBtn}
                      
                    />
                  </Form.Item>
                </Col>

                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                <Form.Item
                    name="orgname"
                    label="Organization Name"
                    // initialValue={userData.emailAddress}
                  >
                    <Input
                      placeholder="Enter Organization Name"
                      disabled={!editBtn}
                      
                    />
                  </Form.Item>
                </Col>
                
                <Col span={8}>
                <Form.Item
                    name="industry"
                    label="Industry"
                    // initialValue={userData.emailAddress}
                  >
                    <Select
                      placeholder="Select Industry"
                      tokenSeparators={[","]}
                      disabled={!editBtn}
                      options={[
                        { label: "AAA Industry", value: "AAA Industry" },
                        { label: "BBB Industry", value: "BBB Industry" },
                        { label: "CCC Industry", value: "CCC Industry" },
                      ]}
                     
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
            <Row align="middle" justify="space-between">
                <Col span={8}>
                <Form.Item
                    name="city"
                    label="City"
                   
                  >
                    <Select
                      placeholder="Select City"
                      tokenSeparators={[","]}
                      disabled={!editBtn}
                      options={[
                        { label: "Chennai", value: "Chennai" },
                        { label: "Pune", value: "Pune" },
                        { label: "Mumbai", value: "Mumbai" },
                      ]}
                     
                    />
                  </Form.Item>
                </Col>
                
                <Col span={8}>
                <Form.Item
                    name="country"
                    label="Country"
                   
                  >
                    <Select
                      placeholder="Select Country"
                      tokenSeparators={[","]}
                      disabled={!editBtn}
                      options={[
                        { label: "USA", value: "usa" },
                        { label: "INDIA", value: "india" },
                        { label: "UK", value: "uk" },
                      ]}
                     
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
            <Row align="middle" justify="space-between">
                <Col span={8}>
                <Form.Item
                    name="state"
                    label="State"
                   
                  >
                    <Select
                      placeholder="Select State"
                      tokenSeparators={[","]}
                      disabled={!editBtn}
                      options={[
                        { label: "Tamilnadu", value: "Tamilnadu" },
                        { label: "Kerela", value: "Kerela" },
                        { label: "Telangana", value: "Telangana" },
                      ]}
                     
                    />
                  </Form.Item>
                </Col>
                
                <Col span={8}>
                <Form.Item
                    name="otp"
                    label="OTP Method"
                    value={userData.otpMethod}
                  >
                    <Select
                      placeholder={userData.otpMethod}
                      tokenSeparators={[","]}
                      disabled={!editBtn}
                     value={userData.otpMethod}
                      options={[
                        { label: "DID", value: "DID" },
                        { label: "SMS", value: "SMS" },
                        { label: "Email", value: "Email" },
                      ]}
                     
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          {editBtn && (
          <Row justify="end" style={{ marginTop: "1rem" }}>
            <Col span={8}>
                
               <Button type="primary" htmlType="submit"  onClick={()=> navigate(-1)}loading={isLoading}>
                  Save
                </Button>
              
            </Col>
          </Row>
          )}
        </Form>
        </Col>
              </Row>
            </Col>
            
            <Col span={6}>
            <div class="group-instruction">
                 <h6 className="para-title">Groups</h6>
                     <p className="body-text">
                     <Link to={`/directory/groups`}><span style={{fontSize:"15px"}}>Groups </span></Link>allow you to manage app
                         assignments and user profile attributes more efficiently.
                        </p>
                             <h6 className="para-title">Converting Assignments</h6>
                                 <p className="body-text">
                                 Application access and user profile attributes can be converted from being
                                 individually-managed to group-managed. You can convert assignments from an app's Group tab.
                                    </p>
            </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UserDetailsProfile;
