import logo from "./logo.svg";
import "./App.css";
import PrimaryLayout from "./components/primaryLayout";
import { useState, useEffect } from "react";
import { getOktaToken } from "./common/token";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { REACT_APP_API, REACT_APP_API_OKTA, REACT_APP_URL } from "./constants";
import { logOutUser } from "./common";
import { Button, Result, Spin } from "antd";
import { authorize } from "./common/SecureRoute";
function App() {
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(localStorage.getItem("token"));
  const [appLoading, setAppLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  let path = window.location.pathname;

  useEffect(() => {
    setAppLoading(true);
    const fetchToken = async (token) => {
      localStorage.setItem("token", token);
      setAuthToken(token);
      requiredAccess(token);
    };

    if (!authToken) {
      const params = new URLSearchParams(window.location.search);
      let session = params.get("session");

      if (session) {
        fetchToken(session);
      } else {
        window.location.replace(`${REACT_APP_API_OKTA}/okta/RedirectLink`);
      }
    } else {
      requiredAccess(authToken);
    }
  }, []);

  const requiredAccess = (token) => {
    setAppLoading(true);
    axios
      .post(
        `${REACT_APP_API}/authnz/getUserDetails`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": `${token}&DOMAIN&${REACT_APP_URL}`,
          },
        }
      )
      .then(({ data }) => {
        setAppLoading(false);
        if (data?.Validation === true && data?.Status === "Success") {
          localStorage.setItem("UserRole", data?.UserRole);
          localStorage.setItem("UserName", data?.Username);
          localStorage.setItem("userId", data?.UserID);
          if (!authorize(data?.UserRole, path)) {
            if (data?.UserRole === "ADMIN") {
              navigate("/");
            } else if (data?.UserRole === "ENDUSER") {
              navigate("/endUser/endpoints");
            }
          } else {
            navigate(path);
          }
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        } else if (
          err?.response?.status === 401 &&
          err.response.data?.Validation === false &&
          err.response.data?.Message === "User Does not exist contact your administrator"
        ) {
          setAppLoading(false);
          setIsUnauthorized(true);
        } else {
          logOutUser();
        }
      });
  };

  return (
    <div className="app-conatiner">
      {!appLoading && authToken ? (
        <PrimaryLayout />
      ) : isUnauthorized && !appLoading ? (
        <Result
          status="403"
          title={`You do not have permission to access this application.`}
          subTitle="Please contact your Site Administrator(s) to request access."
          extra={
            <Button
              type="primary"
              onClick={() => {
                localStorage.clear();
                navigate(`${REACT_APP_API_OKTA}/okta/RedirectLink`);
              }}
            >
              Return to the login
            </Button>
          }
        />
      ) : (
        <div className="app-loading">
          <Spin />
        </div>
      )}
    </div>
  );
}

export default App;
